import { ListItemButton } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';
import { publicRoute } from 'routes';

const StyledListItem = styled(ListItemButton)({
  position: 'relative',
  borderRadius: 20,
  fontWeight: 700,
  color: '#0008',
  whiteSpace: 'nowrap',
  '&:hover, &.Mui-selected': {
    color: '#000',
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
  '@media (min-width: 1024px) and (max-width: 1400px)': {
    fontSize: '14px',
  },
});

const NavItem = ({ path, name, isHot }: { path: string; name: string; isHot?: boolean }) => {
  const { pathname } = useLocation();
  const isHome = path === '/';
  return (
    <Link to={path}>
      <StyledListItem selected={isHome ? pathname === path : pathname.startsWith(path)}>
        {name}
        {isHot && (
          <div className='absolute top-[-8px] right-[8px] bg-[#FF4723] text-white text-sm rounded-tr-xl rounded-bl-xl px-2'>
            HOT
          </div>
        )}
      </StyledListItem>
    </Link>
  );
};

const Menu = () => {
  const { home, marketplace, metaverse, partnership, voting, events } = publicRoute;

  return (
    <>
      <NavItem {...home} />
      <NavItem {...marketplace} />
      <NavItem {...metaverse} />
      <NavItem {...events} />
      <NavItem {...partnership} />
      <NavItem {...voting} isHot />
      <a hidden href='https://mymetafarm.helpshift.com/hc/en' target='_blank' rel='noreferrer'>
        <StyledListItem>
          Support Center
          <img src={require('assets/icons/open.svg').default} className='ml-1' />
        </StyledListItem>
      </a>
      <a href='https://news.mymetafarm.com' target='_blank' rel='noreferrer'>
        <StyledListItem>
          News
          <img src={require('assets/icons/open.svg').default} className='ml-1' />
        </StyledListItem>
      </a>
      <a href='https://docs.mymetafarm.com' target='_blank' rel='noreferrer'>
        <StyledListItem>
          Whitepaper
          <img src={require('assets/icons/open.svg').default} className='ml-1' />
        </StyledListItem>
      </a>
    </>
  );
};

export default Menu;
