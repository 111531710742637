import { Grid, Pagination } from '@mui/material';
import { useSearch } from 'hooks';
import { parse, stringify } from 'qs';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { publicRoute } from 'routes';
import { marketService } from 'services';
import { CardItem } from 'views/Cards';

const TabInventory = () => {
  const { page = 1 } = parse(window.location.search, { ignoreQueryPrefix: true });
  const { address } = useSelector(profileSelector);
  const [dataSearch, onSearchChange] = useSearch({ page, owner: address });

  const { data } = useQuery(['marketService.fetchItems', dataSearch], () => marketService.fetchItems(dataSearch), {
    keepPreviousData: true,
    staleTime: 0,
    onSuccess: () => {
      const query = { ...dataSearch, tab: 'owned', owner: undefined };
      window.history.replaceState(null, '', stringify(query, { addQueryPrefix: true }));
    },
  });
  const { items = [], total = 0, currentPage, pages: totalPage } = data ?? {};

  return (
    <div>
      <div className='mb-6'>
        <div>{total} Assets</div>
      </div>

      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item xl={12 / 5} lg={3} md={4} sm={6} xs={12} key={item.id}>
            <Link to={publicRoute.itemView.url(item)!}>
              <CardItem item={item} />
            </Link>
          </Grid>
        ))}
      </Grid>
      <div className='flex justify-center my-8'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </div>
  );
};

export default TabInventory;
