import { ethers } from 'ethers';
import Web3 from 'web3';
import ERC20 from './abis/ERC20.json';
import ERC721 from './abis/ERC721.json';
import marketplaceAbi from './abis/marketplaceAbi.json';
import metaverseAbi from './abis/metaverseAbi.json';
import whitelistAbi from './abis/whitelistAbi.json';

type ContractType = 'ERC20' | 'ERC721' | 'MARKET' | 'METAVERSE' | 'WHITELIST';

const exportAbi: any = (type: ContractType) => {
  let abi;
  if (type === 'ERC20') abi = ERC20.abi;
  if (type === 'ERC721') abi = ERC721.abi;
  if (type === 'MARKET') abi = marketplaceAbi;
  if (type === 'METAVERSE') abi = metaverseAbi;
  if (type === 'WHITELIST') abi = whitelistAbi;
  return abi;
};

export const web3 = new Web3();

export const web3Contract = (address: string, contract: ContractType) =>
  new web3.eth.Contract(exportAbi(contract), address);

export const ethersContract = (address: string, contract: ContractType, signer?: any) =>
  new ethers.Contract(address, exportAbi(contract), signer);
