import { CardMedia, Container, Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { marketService } from 'services';
import { shorten } from 'utils/common';
import { CardItem } from 'views/Cards';
import { BoxPrice, Model3d } from './components';

const ItemView = () => {
  const params = useParams();

  const { data: item, isSuccess } = useQuery(['marketService.getItemByTokenId', { tokenId: params.tokenId }], () =>
    marketService.getItemByTokenId({ tokenId: params.tokenId as string }),
  ) as { data: ItemType; isSuccess: boolean };

  if (!isSuccess) return <></>;
  return (
    <Container className='py-20'>
      <Grid container spacing={5}>
        <Grid item md={6} xs={12}>
          {item.external.iconType === 'mp4' ? (
            <CardMedia
              component='video'
              image={item.external.iconUrl}
              className='max-w-[600px] rounded-[20px] mx-auto'
              autoPlay
              loop
              muted
              controls
            />
          ) : (
            <div className='md:h-[600px] h-[360px] relative p-8 border'>
              {item.external.modelUrl && item.external.uvUrl ? (
                <Model3d urlModel={item.external.modelUrl} urlTexture={item.external.uvUrl} />
              ) : (
                <CardMedia image={item.external.iconUrl} className='h-full min-h-[240px] bg-contain' />
              )}
            </div>
          )}
        </Grid>
        <Grid item md={6} xs={12} className='flex flex-col items-start'>
          <div className='font-black text-2xl max-line-2'>{item.name}</div>
          <div className='font-bold text-base text-neutral-400'>#{item.tokenId}</div>
          <div className='my-6'>
            <CardItem.Rarity rarity={item.external.rarity} />
          </div>

          <Grid container spacing={4}>
            {item.listedOnMarket && (
              <Grid item>
                <div className='font-semibold text-sm'>Price:</div>
                <div className='font-bold text-orange-700'>
                  {item.sale?.price.toLocaleString()} {item.sale?.paymentToken?.symbol}
                </div>
              </Grid>
            )}
            <Grid item>
              <div className='font-semibold text-sm'>Owner:</div>
              <div className='font-bold'>{shorten(item.ownerAddress)}</div>
            </Grid>

            <Grid item xs={12}>
              <div className='font-semibold text-sm'>Description:</div>
              <div className='font-bold'>{item.external.description}</div>
            </Grid>
          </Grid>

          <div className='flex-1 flex items-end w-full pt-10'>
            <BoxPrice item={item} />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ItemView;
