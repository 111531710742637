import { Close, InfoOutlined, Apple } from '@mui/icons-material';
import { Avatar, Checkbox, Chip, FormControl, InputAdornment, TextField, Tooltip } from '@mui/material';
import { DesignButton } from 'components';
import { useSnackbar } from 'notistack';
import { parse } from 'qs';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { GoogleLoginResponse } from 'react-google-login';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { accountService, trackerService } from 'services';
import { isUsername } from 'utils/validate';

type TabSocialProps = {
  registerType: number;
  registerData: GoogleLoginResponse | ReactFacebookLoginInfo | AppleLoginResponse;
  onBack: () => void;
  onClose: () => void;
};

const TabSocial = ({ registerType, registerData, onClose, onBack }: TabSocialProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { refCode } = parse(location.search, { ignoreQueryPrefix: true });
  const { isLoggedIn, address } = useSelector(profileSelector);

  const { control, handleSubmit } = useForm();

  // 30: Google / 20: Facebook / 40: Apple
  const isGoogle = registerType === 30;
  const isFacebook = registerType === 20;

  const google = registerData as GoogleLoginResponse;
  const facebook = registerData as ReactFacebookLoginInfo;
  const apple = registerData as AppleLoginResponse;

  const { mutate, isLoading } = useMutation(accountService.registerOrLoginSocial, {
    onSuccess: () => {
      enqueueSnackbar('Your account has been successfully created');
      trackerService.trackPixel({ action: 3 });
      onClose();
    },
    onSettled: () => {
      if (isLoggedIn) {
        accountService.getProfile({ address }).then((profile) => {
          dispatch(signIn(profile));
        });
      }
    },
  });

  const handleRegisterSocial = () => {
    handleSubmit(({ refCode, username }) => {
      mutate({
        username,
        token: isGoogle ? google.tokenId : isFacebook ? facebook.accessToken : apple.authorization?.id_token,
        address,
        refCode,
        registerType,
      } as AccountRegisterOrLoginSocialType);
    })();
  };

  return (
    <div className='flex flex-col gap-[24px]'>
      <div className='flex justify-center items-center'>
        {isGoogle ? (
          <Chip
            avatar={<Avatar alt={google.profileObj.name} src={google.profileObj.imageUrl} />}
            label={google.profileObj.name}
            deleteIcon={<Close />}
            onDelete={onBack}
          />
        ) : isFacebook ? (
          <Chip
            avatar={<Avatar alt={facebook.name} src={facebook.picture?.data.url} />}
            label={facebook.name}
            deleteIcon={<Close />}
            onDelete={onBack}
          />
        ) : (
          <Chip
            icon={<Apple />}
            label={apple.user ? `${apple.user?.name.firstName} ${apple.user?.name.lastName}` : 'Apple User'}
            deleteIcon={<Close />}
            onDelete={onBack}
          />
        )}
      </div>

      <Controller
        name='username'
        defaultValue=''
        control={control}
        rules={{
          required: 'Username is required',
          minLength: { value: 6, message: 'Username is 6 characters minimum' },
          validate: {
            pattern: (value) => isUsername(value) || 'Username is not valid',
          },
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Username</label>
            <TextField
              {...field}
              error={invalid}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip title={`Only letters (a-z) and numbers (0-9) are accepted`}>
                      <InfoOutlined className='cursor-pointer text-black/50' />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
      />

      <Controller
        name='refCode'
        defaultValue={refCode ?? ''}
        control={control}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Referral Code (Optional)</label>
            <TextField {...field} type='text' error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />
      <div className='flex sm:items-center items-start'>
        <Checkbox size='small' defaultChecked style={{ padding: 0, marginRight: 4 }} />
        <span className='text-sm'>
          Read and Agree{' '}
          <a href='/' target='_blank' className='underline' rel='noreferrer'>
            Term and Condition
          </a>
        </span>
      </div>
      <div className='flex justify-center'>
        <DesignButton className='px-[48px]' onClick={handleRegisterSocial} loading={isLoading}>
          NEXT
        </DesignButton>
      </div>
    </div>
  );
};

export default TabSocial;
