import { client } from './axios';

const fetchAirdropEvents = (): Promise<AirdropEvent[]> => client.get(`/market-apis/api/airdrop-events`);
const getAirdropEventById = ({ id }: { id: string }): Promise<AirdropEvent> =>
  client.get(`/market-apis/api/airdrop-events/${id}`);

const fetchSales = (params: ItemPaginateParams): Promise<SalePaginateType> =>
  client.get(`/market-apis/api/sale-items`, { params });
const fetchItems = (params: ItemPaginateParams): Promise<ItemPaginateType> =>
  client.get(`/market-apis/api/items`, { params });

const getItemById = ({ id }: { id: string }): Promise<ItemType> => client.get(`/market-apis/api/items/${id}`);
const getItemByTokenId = ({ tokenId }: { tokenId: string }): Promise<ItemType> =>
  client.get(`/market-apis/api/items/token/${tokenId}`);

const getHashMessage = (params: GetHashMessageParams): Promise<GetHashMessageType> =>
  client.get(`/market-apis/api/sale-items/hash-message`, { params });
const createSale = (body: CreateSaleBody): Promise<CreateSaleType> =>
  client.post(`/market-apis/api/sale-items/create`, body);
const deleteSale = ({ id }: { id: string }): Promise<any> => client.delete(`/market-apis/api/sale-items/${id}`);

const genHashMessageMint = (body: GenHashMessageMintBody): Promise<any> =>
  client.post(`/market-apis/api/items/hashmessage-mint`, body);
const genSignatureMint = (body: GenSignatureMintBody): Promise<any> =>
  client.post(`/market-apis/api/items/signature-mint`, body);

export default {
  fetchAirdropEvents,
  getAirdropEventById,

  fetchSales,
  fetchItems,

  getItemById,
  getItemByTokenId,

  getHashMessage,
  createSale,
  deleteSale,

  genHashMessageMint,
  genSignatureMint,
};
