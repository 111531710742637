import { CardMedia, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { ethersContract, web3, web3Contract } from 'contracts';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { glipService, marketService, queryClient, trackerService } from 'services';
import { getPolygonFee, shorten } from 'utils/common';

type PopupProps = PopupController & {
  item: ItemType;
};

const PopupTransfer = ({ item, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isGlip, address } = useSelector(profileSelector);
  const { chainId: appChainId, marketplaceAddress } = useSelector(systemSelector);

  const { mutate, isLoading } = useMutation(
    async () => {
      const maxFeeForFast = (await getPolygonFee(+appChainId)) as number;

      const payload = {
        tokenIds: [item.tokenId],
        nftContract: item.nftContract,
        to: address!,
      };
      const message = await marketService.genHashMessageMint(payload);

      if (isGlip) {
        const glipWallet = await glipService.getGlipWallet();
        const signer = await glipWallet.getSigner();
        const signedSignature = await signer.signMessage(message);
        const signatureVerifier = await marketService.genSignatureMint({ ...payload, signature: signedSignature });

        const trans = await ethersContract(marketplaceAddress, 'MARKET').populateTransaction['mintMultiple'](
          item.nftContract,
          [item.tokenId],
          [signedSignature, signatureVerifier],
        );
        return signer.sendTransaction(trans);
      }

      const signedSignature = await web3.eth.personal.sign(message, address!, '');
      const signatureVerifier = await marketService.genSignatureMint({ ...payload, signature: signedSignature });

      return web3Contract(marketplaceAddress, 'MARKET')
        .methods.mintMultiple(item.nftContract, [item.tokenId], [signedSignature, signatureVerifier])
        .send({
          from: address,
          gasPrice: Math.ceil(maxFeeForFast),
        });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Mint item successfully');
        queryClient.invalidateQueries('marketService.getItemByTokenId');
        trackerService.trackPixel({ action: 7 });
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    mutate();
  };

  return (
    <>
      <DialogTitle>Mint NFT</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={5} xs={12}>
            {item.external.iconType === 'mp4' ? (
              <CardMedia
                component='video'
                image={item.external.iconUrl}
                className='max-w-[240px] rounded-[8px] mx-auto'
                autoPlay
                loop
                muted
              />
            ) : (
              <CardMedia image={item.external.iconUrl} className='h-[240px] rounded-[8px]' />
            )}
          </Grid>
          <Grid item sm={7} xs={12} className='flex flex-col gap-6'>
            <div>
              <div className='text-neutral-400'>You are about to transfer your item</div>
              <div className='font-bold'>#{item.tokenId}</div>
            </div>

            <div>
              <div className='text-neutral-400'>Receive Address</div>
              <div className='flex'>
                <TextField fullWidth value={shorten(address, 12, 10)} InputProps={{ readOnly: true }} />
              </div>

              <div className='text-sm mt-3'>
                This item will be minted in blockchain first before transfering, so you have to pay the minting fee for
                the transfer.
              </div>
            </div>

            <div className='flex-1 flex flex-col justify-end w-full sm:w-40'>
              <DesignButton
                fullWidth
                variant='contained'
                color='secondary'
                loading={isLoading}
                onClick={handleClickSubmit}
              >
                {isLoading ? 'Processing' : 'Confirm'}
              </DesignButton>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <CloseButton onClick={onClose} disabled={isLoading} />
    </>
  );
};

export default PopupTransfer;
