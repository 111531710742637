import { CardMedia, DialogContent, DialogTitle, Grid } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { ethersContract, web3, web3Contract } from 'contracts';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { glipService, queryClient, trackerService } from 'services';
import { getPolygonFee } from 'utils/common';

type PopupProps = PopupController & {
  item: ItemType;
};

const PopupBuy = ({ item, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isGlip, address } = useSelector(profileSelector);
  const { chainId: appChainId, marketplaceAddress } = useSelector(systemSelector);

  const { mutate, isLoading } = useMutation(
    async () => {
      const maxFeeForFast = (await getPolygonFee(+appChainId)) as number;
      const priceInWei = web3.utils.toWei(item.sale?.price.toString()!, 'ether');
      const isNative = item.sale?.paymentToken?.contractAddress === '0x0000000000000000000000000000000000000000';

      if (!isNative && !isGlip) {
        const allowance = await web3Contract(item.sale?.paymentToken?.contractAddress!, 'ERC20')
          .methods.allowance(address, marketplaceAddress)
          .call();
        if (Number(allowance) < Number(priceInWei)) {
          await web3Contract(item.sale?.paymentToken?.contractAddress!, 'ERC20')
            .methods.approve(marketplaceAddress, web3.utils.toTwosComplement(-1))
            .send({ from: address, gasPrice: Math.ceil(maxFeeForFast) });
        }
      }

      if (isGlip) {
        const glipWallet = await glipService.getGlipWallet();
        const signer = await glipWallet.getSigner();
        const trans = await ethersContract(marketplaceAddress, 'MARKET').populateTransaction['matchTransaction'](
          [item.ownerAddress, item.nftContract, item.sale?.paymentToken?.contractAddress],
          [item.tokenId, priceInWei, item.sale?.saltNonce],
          item.sale?.signedSignature,
          isNative ? { value: priceInWei } : {},
        );
        return signer.sendTransaction(trans);
      }

      return web3Contract(marketplaceAddress, 'MARKET')
        .methods.matchTransaction(
          [item.ownerAddress, item.nftContract, item.sale?.paymentToken?.contractAddress],
          [item.tokenId, priceInWei, item.sale?.saltNonce],
          item.sale?.signedSignature,
        )
        .send({
          from: address,
          value: isNative ? priceInWei : undefined,
          gasPrice: Math.ceil(maxFeeForFast),
        });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Purchase item successfully');
        queryClient.invalidateQueries('marketService.getItemByTokenId');
        queryClient.invalidateQueries('marketService.fetchItems');
        queryClient.invalidateQueries('marketService.fetchSales');
        trackerService.trackPixel({ action: 7 });
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    mutate();
  };

  return (
    <>
      <DialogTitle>Purchase</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={5} xs={12}>
            {item.external.iconType === 'mp4' ? (
              <CardMedia
                component='video'
                image={item.external.iconUrl}
                className='max-w-[240px] rounded-[8px] mx-auto'
                autoPlay
                loop
                muted
              />
            ) : (
              <CardMedia image={item.external.iconUrl} className='h-[240px] rounded-[8px]' />
            )}
          </Grid>
          <Grid item sm={7} xs={12} className='flex flex-col gap-6'>
            <div>
              <div className='text-neutral-400'>You are about to buy this item</div>
              <div className='font-bold'>#{item.tokenId}</div>
            </div>

            <div>
              <div className='text-neutral-400'>Price</div>
              <div className='font-bold'>
                {item.sale?.price.toLocaleString()} {item.sale?.paymentToken?.symbol}
              </div>
            </div>

            <div className='flex-1 flex flex-col justify-end w-full sm:w-40'>
              <DesignButton
                fullWidth
                variant='contained'
                color='secondary'
                loading={isLoading}
                onClick={handleClickSubmit}
              >
                {isLoading ? 'Processing' : 'Confirm'}
              </DesignButton>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <CloseButton onClick={onClose} disabled={isLoading} />
    </>
  );
};

export default PopupBuy;
