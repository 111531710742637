import { Container } from '@mui/material';
import styles from './Home.module.scss';
import { styled } from '@mui/system';
import { useWindowDimensions } from 'hooks';

const StyledImage = styled('img')`
  transform: rotate(-90deg);
  @media (max-width: 479px) {
    max-width: 56px;
  }
  @media (max-width: 640px) and (min-width: 480px) {
    max-width: 90px;
  }
`;

const PartnersListWeb = () => {
  return (
    <div className={`${styles.partners} mt-16 flex justify-center`}>
      <div className='flex items-center'>
        <div className='flex flex-col items-center'>
          <a href='https://metaderby.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Metaderby.png').default} />
          </a>
          <a href='https://mooinetwork.io/mooinetwork' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Mooi.png').default} />
          </a>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='flex flex-col'>
          <a href='https://davaproject.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Dava.png').default} />
          </a>
          <a href='https://chainplay.gg' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Chainplay.png').default} />
          </a>
          <a href='https://beta.dequest.io/quests' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/DeQuest.png').default} />
          </a>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='flex flex-col'>
          <a href='https://www.delysium.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Delysium.png').default} />
          </a>
          <a href='https://glip.gg' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Glip.png').default} />
          </a>
        </div>
      </div>
      <div className='flex flex-col'>
        <a href='https://unopnd.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
          <StyledImage src={require('../assets/partners/Unopnd.png').default} />
        </a>
        <a href='https://fi.woo.org' target='_blank' rel='noreferrer' className={styles.hexagon}>
          <StyledImage src={require('../assets/partners/Woofi.png').default} />
        </a>
        <a href='https://www.chainalysis.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
          <StyledImage src={require('../assets/partners/Chainalysis.png').default} />
        </a>
      </div>
      <div className='flex items-center'>
        <div className='flex flex-col'>
          <a href='https://magicsquare.io' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/MagicSquare.png').default} />
          </a>
          <a href='https://symbiosis.finance' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Symbiosis.png').default} />
          </a>
        </div>
      </div>
      <div className='flex items-end'>
        <div className='flex flex-col'>
          <a href='https://fruttidino.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/FruttiDino.png').default} />
          </a>
          <a href='https://www.voiceverse.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/VoiceVerse.png').default} />
          </a>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='flex flex-col'>
          <a href='https://questfi.gg' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/QuestFi.png').default} />
          </a>
          <a href='https://www.envelop.is' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Envelop.png').default} />
          </a>
        </div>
      </div>
    </div>
  );
};

const PartnersListMobile = () => {
  return (
    <div className={`${styles.partners} mt-12 flex justify-center`}>
      <div className='flex items-end'>
        <div className='flex flex-col'>
          <a href='https://metaderby.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Metaderby.png').default} />
          </a>
          <a href='https://chainplay.gg' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Chainplay.png').default} />
          </a>
          <a href='https://beta.dequest.io/quests' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/DeQuest.png').default} />
          </a>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='flex flex-col'>
          <a href='https://www.delysium.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Delysium.png').default} />
          </a>
          <a href='https://glip.gg' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Glip.png').default} />
          </a>
          <a href='https://davaproject.com/' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Dava.png').default} />
          </a>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='flex flex-col'>
          <a href='https://fi.woo.org' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Woofi.png').default} />
          </a>
          <a href='https://unopnd.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Unopnd.png').default} />
          </a>
          <a href='https://www.chainalysis.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Chainalysis.png').default} />
          </a>
          <a href='https://www.voiceverse.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/VoiceVerse.png').default} />
          </a>
        </div>
      </div>
      <div className='flex items-center'>
        <div className='flex flex-col'>
          <a href='https://magicsquare.io' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/MagicSquare.png').default} />
          </a>
          <a href='https://symbiosis.finance' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Symbiosis.png').default} />
          </a>
          <a href='https://fruttidino.com' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/FruttiDino.png').default} />
          </a>
        </div>
      </div>
      <div className='flex items-end'>
        <div className='flex flex-col'>
          <a href='https://questfi.gg' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/QuestFi.png').default} />
          </a>
          <a href='https://www.envelop.is' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Envelop.png').default} />
          </a>
          <a href='https://mooinetwork.io/mooinetwork' target='_blank' rel='noreferrer' className={styles.hexagon}>
            <StyledImage src={require('../assets/partners/Mooi.png').default} />
          </a>
        </div>
      </div>
    </div>
  );
};

const Partners = () => {
  const { isLgScreen } = useWindowDimensions();
  return (
    <Container className='py-[60px] z-10 relative'>
      <div className={styles.header}>Partners</div>
      <Container maxWidth='lg' className='px-0'>
        <div className='text-base sm:text-xl text-neutral-500 text-center'>
          We are proud to be working with leading projects across the world
        </div>
        {isLgScreen ? <PartnersListMobile /> : <PartnersListWeb />}
      </Container>
      <div className='sm:block'>
        <img
          src={require('../assets/icons/animate-cloud.png').default}
          className='absolute top-[60px] left-10 hidden xl:block w-[160px] xl:w-auto go-jump'
        />
        <img
          src={require('../assets/icons/animate-rocket.png').default}
          className='absolute top-[60px] right-0 sm:right-10 xl:block w-[68px] sm:w-[100px] lg:w-[160px] xl:w-auto rocket-spin'
        />
      </div>
    </Container>
  );
};

export default Partners;
