import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';

const SocialJoinNow = () => {
  const [isVisible, setVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className='bg-[#00338E] fixed left-0 bottom-1/3 2xl:bottom-1/2 flex flex-col items-center space-y-3 p-2 sm:p-4 rounded-tr-xl rounded-br-xl z-50 w-[80px] sm:w-auto'>
      <div className='absolute bottom-[-40px] left-0 right-0 flex justify-center'>
        <Button
          size='small'
          color='inherit'
          className='w-[32px] h-[32px] rounded-full'
          onClick={() => setVisible(false)}
        >
          <Close fontSize='small' />
        </Button>
      </div>

      <div className='text-[10px] sm:text-xs text-white font-black'>JOIN NOW</div>
      <a href='https://twitter.com/mymetafarm' target='_blank' rel='noreferrer'>
        <img
          src={require('../assets/icons/socials/twitter-icon.png').default}
          alt='TWITTER'
          className='w-[48px] sm:w-auto'
        />
      </a>
      <a href='https://discord.gg/MyMetaFarm' target='_blank' rel='noreferrer'>
        <img
          src={require('../assets/icons/socials/discord-icon.png').default}
          alt='DISCORD'
          className='w-[48px] sm:w-auto'
        />
      </a>
    </div>
  );
};

export default SocialJoinNow;
