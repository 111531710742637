import { GLIP_CLIENT_ID } from 'env';
import { glipWalletSDK } from 'glip-wallet-sdk/wallet';
import { signIn } from 'reducers/profileSlice';
import { store } from 'reducers/store';
import { accountService, authService } from 'services';

let initializedGlipWallet: any;

const getGlipWallet: any = async () => {
  if (initializedGlipWallet) {
    return initializedGlipWallet;
  }
  const { chainId, chainName } = store.getState().system;
  await glipWalletSDK.init({
    chainId: +chainId,
    authNetwork: chainName,
    clientIdentifier: GLIP_CLIENT_ID!,
  });
  initializedGlipWallet = glipWalletSDK;
  return glipWalletSDK;
};

const connectWallet = async () => {
  const glipWallet = await getGlipWallet();
  const publicAddress = await glipWallet.login('google', window.location.href, {});
  const { nonce, address } = await authService.getNonce({ address: publicAddress.toLowerCase() });

  const signer = await glipWallet.getSigner();
  const signature = await signer.signMessage(`${nonce}`);
  const { accessToken } = await authService.getToken({ address, signature });
  store.dispatch(signIn({ isGlip: true, address, accessToken }));

  accountService.getProfile({ address }).then((profile) => {
    store.dispatch(signIn(profile));
  });
};

export default {
  getGlipWallet,
  connectWallet,
};
