import { Controller, useForm } from 'react-hook-form';
import { FormControl, Avatar, Container } from '@mui/material';
import { DesignButton, InputPassword } from 'components';
import { useMutation } from 'react-query';
import { accountService } from 'services';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { parse } from 'qs';
import { useEffect, useState } from 'react';
import { publicRoute } from 'routes';

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { address, email, isLoggedIn } = useSelector(profileSelector);

  const { control, handleSubmit, watch } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { new_password } = watch();

  const [isExpired, setIsExpired] = useState(false);

  const { code } = parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (code && email) {
      accountService
        .forgotPasswordCheck({ email, reset_code: code as string })
        .then(() => setIsExpired(true))
        .catch(() => navigate(publicRoute.profile.path, { replace: true }));
    }
  }, [code, email, navigate]);

  const { mutate, isLoading } = useMutation(accountService.forgotPasswordReset, {
    onSuccess: () => {
      enqueueSnackbar('Reset Password Successfully');
      navigate(publicRoute.profile.path, { replace: true });
    },
    onSettled: () => {
      if (isLoggedIn) {
        accountService.getProfile({ address }).then((profile) => {
          dispatch(signIn(profile));
        });
      }
    },
    onError: () => {
      setIsExpired(false);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutate({
        ...values,
        email,
        reset_code: code,
      } as AccountForgotPasswordResetType);
    })();
  };

  if (!isExpired) return null;

  return (
    <Container className='py-20'>
      <div className='flex flex-col sm:flex-row justify-center'>
        <Avatar
          src={require('assets/images/profile-avatar.png').default}
          className='md:w-[120px] md:h-[120px] w-[100px] h-[100px] mr-12 bg-red-100 border-2'
        />

        <div className='flex flex-col gap-[24px] sm:min-w-[420px]'>
          <div>
            <div className='font-bold text-2xl pt-[15px] sm:pt-[0px]'>Set New Password</div>
          </div>
          <Controller
            name='new_password'
            defaultValue=''
            control={control}
            rules={{
              required: 'Password is required',
              minLength: { value: 6, message: 'Password is 6 characters minimum' },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth>
                <label>Password</label>
                <InputPassword {...field} error={invalid} helperText={error?.message} />
              </FormControl>
            )}
          />
          <Controller
            name='passwordConfirm'
            defaultValue=''
            control={control}
            rules={{
              required: 'Confirm Password is required',
              validate: {
                match: (value) => value === new_password || 'Confirm Password does not match',
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl fullWidth>
                <label>Confirm Password</label>
                <InputPassword {...field} error={invalid} helperText={error?.message} />
              </FormControl>
            )}
          />
          <div className='flex justify-center'>
            <DesignButton className='px-[48px]' loading={isLoading} onClick={handleClickSubmit}>
              CONFIRM
            </DesignButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ResetPasswordForm;
