import { CardMedia, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { CloseButton, DesignButton, InputNumber } from 'components';
import { web3, web3Contract } from 'contracts';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { glipService, marketService, queryClient, systemService, trackerService } from 'services';
import { getPolygonFee } from 'utils/common';

type PopupProps = PopupController & {
  item: ItemType;
};

const PopupListing = ({ item, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isGlip, address } = useSelector(profileSelector);
  const { chainId: appChainId, marketplaceAddress } = useSelector(systemSelector);

  const { data: payments } = useQuery(['fetchPayments'], () => systemService.fetchPayments());

  const { control, handleSubmit, getValues } = useForm();

  const { mutate, isLoading } = useMutation(
    async () => {
      const maxFeeForFast = (await getPolygonFee(+appChainId)) as number;

      if (!isGlip) {
        const isApprovedForAll = await web3Contract(item.nftContract, 'ERC721')
          .methods.isApprovedForAll(address, marketplaceAddress)
          .call();
        if (!isApprovedForAll) {
          await web3Contract(item.nftContract, 'ERC721')
            .methods.setApprovalForAll(marketplaceAddress, true)
            .send({
              from: address,
              gasPrice: Math.ceil(maxFeeForFast),
            });
        }
      }

      const payload = {
        nftItemId: item.id,
        saltNonce: new Date().getTime(),
        ownerAccept: true,
        ...getValues(),
      } as GetHashMessageParams;

      const { hashMessage: message } = await marketService.getHashMessage(payload);
      let signedSignature;

      if (isGlip) {
        const glipWallet = await glipService.getGlipWallet();
        const signer = await glipWallet.getSigner();
        signedSignature = await signer.signMessage(message);
      } else {
        signedSignature = await web3.eth.personal.sign(message, address!, '');
      }
      return marketService.createSale({ ...payload, signedSignature });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Put on market successfully');
        queryClient.invalidateQueries('marketService.getItemByTokenId');
        queryClient.invalidateQueries('marketService.fetchItems');
        queryClient.invalidateQueries('marketService.fetchSales');
        trackerService.trackPixel({ action: 7 });
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit(() => {
      mutate();
    })();
  };

  return (
    <>
      <DialogTitle>Listing on marketplace</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={5} xs={12}>
            {item.external.iconType === 'mp4' ? (
              <CardMedia
                component='video'
                image={item.external.iconUrl}
                className='max-w-[240px] rounded-[8px] mx-auto'
                autoPlay
                loop
                muted
              />
            ) : (
              <CardMedia image={item.external.iconUrl} className='h-[240px] rounded-[8px]' />
            )}
          </Grid>
          <Grid item sm={7} xs={12} className='flex flex-col gap-6'>
            <div>
              <div className='text-neutral-400'>You are about to sell your item</div>
              <div className='font-bold'>#{item.tokenId}</div>
            </div>

            <div>
              <div className='text-neutral-400'>Price</div>
              <div className='flex'>
                <Controller
                  name='price'
                  defaultValue=''
                  control={control}
                  rules={{ required: 'Price is required' }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      InputProps={{
                        inputComponent: InputNumber,
                        style: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                        inputProps: { maxLength: 12 },
                      }}
                      disabled={isLoading}
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
                <Controller
                  name='paymentTokenId'
                  defaultValue={payments?.[0].id}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      InputProps={{ style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: 100 } }}
                      disabled={isLoading}
                    >
                      {payments?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.symbol}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </div>

              <div className='text-sm mt-3'>
                Your item won't be minted in blockchain, it will be stored for further minting by buyer.
              </div>
            </div>

            <div className='flex-1 flex flex-col justify-end w-full sm:w-40'>
              <DesignButton
                fullWidth
                variant='contained'
                color='secondary'
                loading={isLoading}
                onClick={handleClickSubmit}
              >
                {isLoading ? 'Processing' : 'Confirm'}
              </DesignButton>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <CloseButton onClick={onClose} disabled={isLoading} />
    </>
  );
};

export default PopupListing;
