import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CloseButton, QrCodeGenerate } from 'components';
import { copy } from 'utils/common';

type PopupProps = PopupController & {
  text: string;
};

const PopupQrCode = ({ text, onClose }: PopupProps) => {
  return (
    <>
      <DialogTitle>Your Referral Link</DialogTitle>
      <DialogContent>
        <div className='flex justify-center mt-4 mb-8'>
          <QrCodeGenerate value={text} />
        </div>
        <div className='flex items-center bg-black/5 rounded px-3 py-1'>
          <div className='font-bold text-xl max-line-1 w-[320px]'>{text}</div>
          <IconButton onClick={() => copy(text)} title='Copy'>
            <img src={require('assets/icons/copy.svg').default} />
          </IconButton>
        </div>
      </DialogContent>
      <CloseButton onClick={onClose} />
    </>
  );
};

export default PopupQrCode;
