import axios from 'axios';
import { getCookie } from 'utils/common';

const trackPixel = ({ action }: { action: number }) => {
  const vid = getCookie('vid');
  vid && axios.get(`https://magic.lol/4bbad3f1/brokers/pixel?action=${action}&vid=${vid}`).catch(console.warn);
};

export default {
  trackPixel,
};
