import { Share, WarningAmberRounded } from '@mui/icons-material';
import { Button, CardMedia, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { ethersContract, web3Contract } from 'contracts';
import { useSnackbar } from 'notistack';
import { stringify } from 'qs';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { publicRoute } from 'routes';
import { glipService, queryClient, submissionService } from 'services';
import { copy, shorten } from 'utils/common';

type PopupProps = PopupController & {
  item: SubmissionType;
};

const PopupVote = ({ item, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isGlip, address } = useSelector(profileSelector);

  const { data: balanceOf = '0', isFetched } = useQuery(['balanceOf', item.nftContract, address], async () => {
    if (isGlip) {
      const glipWallet = await glipService.getGlipWallet();
      const signer = await glipWallet.getSigner();
      return ethersContract(item.nftContract, 'ERC721', signer).balanceOf(address);
    } else {
      return web3Contract(item.nftContract, 'ERC721').methods.balanceOf(address).call();
    }
  });

  const noMoney = !(balanceOf > 0);
  const url =
    window.location.origin + publicRoute.voting.path + stringify({ subId: item.id }, { addQueryPrefix: true });

  const { mutate, isLoading } = useMutation(submissionService.createVote, {
    onSuccess: () => {
      enqueueSnackbar('Thank you for your vote');
      queryClient.invalidateQueries('submissionService.fetchSubmissions');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    mutate({ id: item.id });
  };

  return (
    <>
      <DialogTitle>Custom Design Voting</DialogTitle>
      <DialogContent>
        <div className='flex justify-center'>
          <CardMedia image={item.imageUrl} className='w-[240px] h-[240px] rounded-[8px]' />
        </div>
        <div dangerouslySetInnerHTML={{ __html: item.description }} className='my-3' />
        {isFetched && noMoney && (
          <div className='flex flex-col items-center mb-3'>
            <div className='text-secondary-main text-center mb-3'>
              <WarningAmberRounded /> You must have at least one NFT to join voting.
              <div>MMF NFT now can be traded on:</div>
            </div>
            <div>
              <ul className='flex gap-2 flex-wrap list-disc'>
                {[
                  { url: 'https://opensea.io/collection/mymetafarm-official', name: 'OpenSea' },
                  { url: 'https://mymetafarm.com/marketplace', name: 'MMF Marketplace' },
                  { url: 'https://tofunft.com/collection/my-meta-farm', name: 'TofuNFT' },
                  { url: 'https://nftrade.com/collection/my-meta-farm', name: 'NFTrade' },
                ].map((item, index) => (
                  <li key={index} className='w-[45%]'>
                    <a
                      href={item.url}
                      className='font-bold text-blue-200 hover:text-blue-300 underline'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className='flex items-stretch gap-6 p-3 border-2 border-dashed rounded-[8px]'>
          <div>
            <div>Creator</div>
            <div>{shorten(item.creator)}</div>
          </div>
          <div>
            <div>Votes</div>
            <div>{item.voteAmount}</div>
          </div>
          <div className='flex-1'></div>

          <Tooltip title='Copy Link'>
            <Button color='inherit' onClick={() => copy(url)}>
              <Share />
            </Button>
          </Tooltip>
          {false && (
            <DesignButton
              variant='contained'
              color='secondary'
              disabled={noMoney}
              loading={isLoading}
              onClick={handleClickSubmit}
            >
              VOTE NOW
            </DesignButton>
          )}
        </div>
      </DialogContent>
      <CloseButton onClick={onClose} disabled={isLoading} />
    </>
  );
};

export default PopupVote;
