import { Button, CardMedia, Container } from '@mui/material';
import { styled } from '@mui/styles';
import { useTabs } from 'hooks';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { marketService } from 'services';
import { CloudAnimate, TabEvents } from './components';

const ButtonCloud = styled(Button)({
  background: `url(${require('assets/components/tab_cloud.png').default})`,
  backgroundSize: 'cover',
  width: 210,
  height: 116,
  '&:hover': {
    filter: 'brightness(0.95)',
  },
  '@media (max-width: 800px)': {
    width: (210 * 2) / 3,
    height: (116 * 2) / 3,
    fontSize: '18px !important',
  },
});

const Metaverse = () => {
  const { data: events } = useQuery(['marketService.fetchAirdropEvents'], () =>
    marketService.fetchAirdropEvents().then((events) =>
      events.map((event) => ({
        ...event,
        isStarted: DateTime.fromISO(event.fromDate) < DateTime.now() || !event.fromDate,
        isFinished: DateTime.fromISO(event.toDate) < DateTime.now(),
      })),
    ),
  );

  const tabs = [
    {
      code: 'opening',
      label: 'OPENING',
      component: <TabEvents items={events?.filter((item) => item.isStarted)} />,
    },
    {
      code: 'upcoming',
      label: 'UPCOMING',
      component: <TabEvents items={events?.filter((item) => !item.isStarted)} />,
    },
  ];

  const [activeTab, onTabChange] = useTabs(tabs);

  return (
    <>
      <CardMedia
        component='video'
        src={require('assets/videos/metaverse-web.mp4').default}
        autoPlay
        loop
        muted
        controls={false}
        className='hidden sm:block'
      />
      <CardMedia
        component='video'
        src={require('assets/videos/metaverse-mobile.mp4').default}
        autoPlay
        loop
        muted
        controls={false}
        className='block sm:hidden'
      />

      <div style={{ background: 'linear-gradient(180deg, #C2F0FF 0%, #C2F0FF 80%, #0000 100%)' }}>
        <Container className='py-20 relative'>
          <div className='flex justify-center gap-6'>
            {tabs.map((tab) => (
              <ButtonCloud
                key={tab.code}
                variant='text'
                color='info'
                className='font-black text-2xl rounded-[100%]'
                style={{ opacity: activeTab === tab.code ? 1 : 0.6 }}
                onClick={(event) => onTabChange(event, tab.code)}
              >
                {tab.label}
              </ButtonCloud>
            ))}
          </div>
          {tabs.map((tab) => (
            <div key={tab.code} hidden={tab.code !== activeTab} className='mt-10'>
              {tab.component}
            </div>
          ))}

          <CloudAnimate />
        </Container>
      </div>
    </>
  );
};

export default Metaverse;
