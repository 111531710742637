import { CardMedia, DialogContent, DialogTitle, Grid } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { ethersContract, web3Contract } from 'contracts';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { publicRoute } from 'routes';
import { glipService, queryClient } from 'services';
import { getPolygonFee, randomTokenID } from 'utils/common';

type PopupProps = PopupController & {
  item: AirdropItem;
};

const PopupAirdrop = ({ item, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isGlip, address } = useSelector(profileSelector);
  const { chainId: appChainId, metaverseContractAddress } = useSelector(systemSelector);

  const {
    mutate: claim,
    isLoading,
    isSuccess,
  } = useMutation(
    async () => {
      if (isGlip) {
        const glipWallet = await glipService.getGlipWallet();
        const signer = await glipWallet.getSigner();
        const trans = await ethersContract(metaverseContractAddress, 'METAVERSE').populateTransaction['claim721Event'](
          item.onchainId,
          randomTokenID(),
        );
        return signer.sendTransaction(trans);
      }

      const maxFeeForFast = (await getPolygonFee(+appChainId)) as number;
      return web3Contract(metaverseContractAddress, 'METAVERSE')
        .methods.claim721Event(item.onchainId, randomTokenID())
        .send({
          from: address,
          gasPrice: Math.ceil(maxFeeForFast),
        });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Claim airdrop successfully');
        queryClient.invalidateQueries('metaverseContract.metaverseEventClaims');
        queryClient.invalidateQueries('marketService.fetchItems');
      },
    },
  );

  return (
    <>
      <DialogTitle>Airdrop</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={5.5}>
            <div className='flex justify-center items-center h-[180px]'>
              {item.itemVideo ? (
                <CardMedia
                  component='video'
                  image={item.itemVideo}
                  className='max-w-[180px] rounded-[8px]'
                  autoPlay
                  loop
                  muted
                />
              ) : (
                <CardMedia image={item.itemImage} className='h-full flex-1 bg-contain' />
              )}
            </div>
          </Grid>
          <Grid item xs={6} className='flex flex-col gap-6'>
            <div>
              <div className='text-neutral-400'>You are about to claim airdrop</div>
              <div className='font-bold'>{item.name}</div>
            </div>

            <div className='flex-1 flex items-end'>
              {isSuccess ? (
                <Link to={{ pathname: publicRoute.inventory.path, search: 'tab=owned' }}>
                  <DesignButton color='secondary' className='w-40'>
                    To Inventory
                  </DesignButton>
                </Link>
              ) : (
                <DesignButton className='w-40' loading={isLoading} onClick={() => claim()}>
                  {isLoading ? 'Processing' : 'CLAIM'}
                </DesignButton>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <CloseButton onClick={onClose} disabled={isLoading} />
    </>
  );
};

export default PopupAirdrop;
