import CalendarComponent from './components/Calendar';
import TopPage from './components/TopLogo';

const Events = () => {
  return (
    <div>
      <TopPage />
      <CalendarComponent />
    </div>
  );
};

export default Events;
