import { Events } from 'views/Events';
import { Home, Partnership } from 'views/Home';
import { Inventory } from 'views/Inventory';
import { ItemView } from 'views/ItemView';
import { Marketplace } from 'views/Marketplace';
import { Metaverse, MetaverseEvent } from 'views/Metaverse';
import { Profile, ResetPasswordForm } from 'views/Profile';
import { Vote } from 'views/Vote';

const publicRoute = {
  home: {
    path: '/',
    name: 'Home',
    component: Home,
  },
  partnership: {
    path: '/partnership',
    name: 'Partnership',
    component: Partnership,
  },
  marketplace: {
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace,
  },
  metaverse: {
    path: '/metaverse',
    name: 'Metaverse',
    component: Metaverse,
  },
  voting: {
    path: '/online-vote',
    name: 'Online Vote',
    component: Vote,
  },
  events: {
    path: '/events',
    name: 'Events',
    component: Events,
  },
  metaverseEvent: {
    path: '/metaverse/:id',
    url: ({ id }: { id: string }) => `/metaverse/${id}`,
    component: MetaverseEvent,
  },
  itemView: {
    path: '/items/:tokenId',
    url: ({ tokenId }: { tokenId: string }) => `/items/${tokenId}`,
    component: ItemView,
  },

  profile: {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    requiredLogin: true,
  },

  resetPassword: {
    path: '/profile/reset-password',
    component: ResetPasswordForm,
    requiredLogin: true,
  },

  inventory: {
    path: '/inventory',
    name: 'Inventory',
    component: Inventory,
    requiredLogin: true,
  },
};

export default publicRoute;
