import { Container, Grid } from '@mui/material';
import { DesignButton, InfiniteSlider } from 'components';
import { useWindowDimensions } from 'hooks';
import { Link } from 'react-router-dom';
import styles from './Home.module.scss';

const items = [
  { image: require('../assets/videos/metaverse-2.gif').default },
  { image: require('../assets/videos/metaverse-1.gif').default },
  { image: require('../assets/videos/metaverse-3.gif').default },
];

const MetaverseWorld = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <div
      style={{ background: `url(${require('../assets/images/background-metaverse.png').default})` }}
      className='bg-no-repeat bg-center bg-cover'
    >
      <Container className='py-[60px] relative'>
        <div className={styles.header}>Metaverse World</div>
        <Container maxWidth='md' className='px-0 mb-8 sm:mb-14'>
          <div className='text-base sm:text-xl text-neutral-500 text-center'>
            Where citizens can play, build, own, and monetize virtual experiences. A virtual world allows interacting,
            exchanging, socializing seamlessly with multi other worlds.
          </div>
        </Container>

        {isMobile ? (
          <div>
            <InfiniteSlider {...{ autoplay: true, dots: true, arrows: false }}>
              {items.map((item, index) => (
                <div key={index} className='h-[320px] flex justify-center mx-auto'>
                  <img src={item.image} className='border-[4px] border-white rounded-[12px]' />
                </div>
              ))}
            </InfiniteSlider>
          </div>
        ) : (
          <Grid container spacing={6} className='justify-center'>
            {items.map((item, index) => (
              <Grid item key={index}>
                <img src={item.image} className='h-[380px] border-[4px] border-white rounded-[12px]' />
              </Grid>
            ))}
          </Grid>
        )}
        <div className='flex justify-center mt-10'>
          <Link to='/metaverse'>
            <DesignButton>EXPLORE NOW</DesignButton>
          </Link>
        </div>

        <img
          src={require('../assets/icons/animate-helicopter.png').default}
          className='absolute top-0 right-[-60px] w-[200px] hidden md:block'
          data-aos='fade-up-left'
          data-aos-duration='800'
        />
      </Container>
    </div>
  );
};

export default MetaverseWorld;
