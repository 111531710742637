import { Container, Grid } from '@mui/material';
import styles from './Home.module.scss';
import { useWindowDimensions } from 'hooks';
import { InfiniteSlider } from 'components';

const teams = [
  {
    name: 'MR. THANH LE - CEO',
    image: require('../assets/images/AThanhCEO.png').default,
    description: 'Professional Game Studio 13 years build game on Steam',
  },
  {
    name: 'MR. SANG LY - CTO',
    image: require('../assets/images/ASangCTO.png').default,
    description: 'Blockchain Development Expert Former Pro Gamer and Game DEV',
  },
  {
    name: 'MR. SON VU - COO',
    image: require('../assets/images/ASonCOO.png').default,
    description: 'MBA & PMP Former Bosch Former Macquarie Access Ltd',
  },
  {
    name: 'MR. TRIEU NGUYEN - CPO',
    image: require('../assets/images/ATrieuCPO.png').default,
    description: 'Blockchain Researcher Former Gunstar Marketing Strategist',
  },
];

const TeamDetail = ({
  name,
  image,
  description,
  isMobile,
}: {
  name: string;
  image: string;
  description: string;
  isMobile: boolean;
}) => {
  return (
    <div className='flex flex-col items-center'>
      <div className='w-[120px] h-[120px] sm:w-[212px] sm:h-[212px] bg-white rounded-full relative'>
        <div
          className='absolute m-[6px] sm:m-3 rounded-full top-0 left-0 bottom-0 right-0'
          style={{
            backgroundImage: `url(${image}), linear-gradient(71.22deg, #fee2b7 -9.37%, #ffb3da 45.29%, #9ae1ff 101.11%)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: isMobile ? 'contain' : 'auto',
          }}
        ></div>
      </div>
      <div className='text-neutral-500 text-xs sm:text-base font-extrabold my-4'>{name}</div>
      <div className='text-neutral-500 text-sm sm:text-base break-words text-center'>{description}</div>
    </div>
  );
};

const Team = () => {
  const { isMobile } = useWindowDimensions();
  return (
    <Container className='pt-4 pb-[120px] md:pb-[60px]'>
      <div className={styles.header}>Team</div>
      {isMobile ? (
        <div>
          <InfiniteSlider
            {...{
              autoplay: true,
              dots: true,
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
            }}
          >
            {teams.map((item, index) => (
              <TeamDetail isMobile={true} {...item} key={`team-${index}`} />
            ))}
          </InfiniteSlider>
        </div>
      ) : (
        <Grid container spacing={6}>
          {teams.map((item, index) => (
            <Grid item xs={6} md={3} className='flex flex-col items-center text-center' key={`team-${index}`}>
              <TeamDetail isMobile={false} {...item} />
            </Grid>
          ))}
        </Grid>
      )}
      <Container maxWidth='md' className='mt-20 mb-40 bg-white relative rounded-2xl p-2'>
        <div
          style={{
            background: 'linear-gradient(180deg, #F3E8FE 0%, #E3FAFF 54.51%, #FFF3E3 100%)',
          }}
          className='p-4 rounded-2xl'
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <img src={require('../assets/images/TeamMarketing.png').default} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={require('../assets/images/TeamDev.png').default} />
            </Grid>
          </Grid>
        </div>
        <img
          src={require('../assets/icons/icon-star-3.png').default}
          className='absolute top-[-16px] left-[-20px] w-[58px]'
        />
        <img
          src={require('../assets/icons/icon-lili-bay.png').default}
          className='absolute bottom-[-44px] right-[-56px] hidden lg:block'
        />
        <Container
          maxWidth='sm'
          className='bg-white p-6 absolute right-0 left-0 mx-auto bottom-[-188px] sm:bottom-[-142px] md:bottom-[-74px] rounded-[20px] text-center'
        >
          <div className='text-2xl font-extrabold text-[#E46F76]'>Highly Enthusiastic Team</div>
          <div className='mt-2'>
            “My Meta Farm is built by a team of experienced Developers, Marketers, and Researchers in blockchain
            background. We are always dedicated to bringing a great project to our dear users.”
          </div>
        </Container>
      </Container>
    </Container>
  );
};

export default Team;
