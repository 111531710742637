import { CardMedia, Container, Grid, Paper } from '@mui/material';
import { DesignButton, InfiniteSlider } from 'components';
import { useWindowDimensions } from 'hooks';
import { memo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ResponsiveContainer, StackedCarousel } from 'react-stacked-center-carousel';
import { shorten } from 'utils/common';
import { ChipItemRarity } from 'views/Cards/CardItem';
import styles from './Home.module.scss';

const items = [
  {
    name: 'Violet Honored Citizen Card',
    tokenId: 6961609244484,
    external: { rarity: 'Epic', iconUrl: 'https://d9hvi1ehclsbh.cloudfront.net/32/violet_card.mp4', iconType: 'mp4' },
    sale: { price: 186 },
    ownerAddress: '0xE31Cc7E1DAa1fE565eBF8D58629034611C57FC03',
  },
  {
    name: 'Ao Dai Pink',
    tokenId: 6961609257319,
    external: { rarity: 'Rare', iconUrl: 'https://d9hvi1ehclsbh.cloudfront.net/39/39_icon.png' },
    sale: { price: 14 },
    ownerAddress: '0xeBb3c9690106cd162e2482f129349482360ccC86',
  },
  {
    name: 'Celtics charm varsity jacket',
    tokenId: 6961609246479,
    external: { rarity: 'Rare', iconUrl: 'https://d9hvi1ehclsbh.cloudfront.net/25/25_icon.png' },
    sale: { price: 32 },
    ownerAddress: '0x8f69144f3b10156c9498aCEDB7731263373E12E6',
  },
  {
    name: 'Angel Wings',
    tokenId: 6961609247275,
    external: { rarity: 'Rare', iconUrl: 'https://d9hvi1ehclsbh.cloudfront.net/17/17_icon.png' },
    sale: { price: 24 },
    ownerAddress: '0x846e4ea67f602e5C1Cb121123c5B7000d4CFcC2B',
  },
  {
    name: 'Hanbok',
    tokenId: 6961609243154,
    external: { rarity: 'Rare', iconUrl: 'https://d1cqw9qrof1e8g.cloudfront.net/Hanbok.png' },
    sale: { price: 1214 },
    ownerAddress: '0xB50556C66DD338A9e2fceec0DEbA30B4A1F117f4',
  },
];

const Slide = memo((props: any) => {
  const { data, dataIndex } = props;
  const item = data[dataIndex] as ItemType;
  return (
    <Paper className='hover:shadow-card rounded-[8px] p-[24px] w-[280px] m-[10px]'>
      <div className='font-black text-2xl max-line-1'>{item.name}</div>
      <div className='font-semibold text-sm'>#{item.tokenId}</div>
      <div className='relative mt-3'>
        {item.external.iconType === 'mp4' ? (
          <CardMedia
            component='video'
            image={item.external.iconUrl}
            className='max-w-[240px] rounded-[8px] mx-auto'
            autoPlay
            loop
            muted
          />
        ) : (
          <CardMedia image={item.external.iconUrl} className='h-[240px] rounded-[8px]' />
        )}
        <div className='absolute top-3 left-3'>
          <ChipItemRarity rarity={item.external.rarity as RarityType} />
        </div>
      </div>
      <Grid container spacing={1} className='flex-col mt-3'>
        <Grid item className='flex items-center justify-between'>
          <div className='font-semibold text-sm'>Price:</div>
          <div className='font-bold'>{item.sale?.price} MATIC</div>
        </Grid>
        <Grid item className='flex items-center justify-between'>
          <div className='font-semibold text-sm'>Owner:</div>
          <div className='font-bold'>{shorten(item.ownerAddress)}</div>
        </Grid>
      </Grid>
    </Paper>
  );
});

const MarketplaceBanner = () => {
  const carousel = useRef();
  const { isMobile } = useWindowDimensions();

  return (
    <div style={{ background: '#F4E9FE' }}>
      <Container className='py-[60px]'>
        <div className={styles.header}>Marketplace</div>
        <Container maxWidth='lg' className='px-0'>
          <div className='text-base sm:text-xl text-neutral-500 text-center'>
            Welcome to our marketplace. This is where you can discover collections, buy, sell and trade the NFTs to
            build your own unique experiences in our game.
          </div>
        </Container>

        {isMobile ? (
          <div>
            <InfiniteSlider {...{ autoplay: true, dots: false, arrows: false, variableWidth: true }}>
              {items.map((item) => (
                <Paper
                  key={item.name}
                  className='hover:shadow-card rounded-[8px] p-[24px] w-[320px] sm:w-[280px] m-[10px] my-[20px]'
                >
                  <div className='font-black text-2xl max-line-1'>{item.name}</div>
                  <div className='font-semibold text-sm'>#{item.tokenId}</div>
                  <div className='relative mt-3'>
                    {item.external.iconType === 'mp4' ? (
                      <CardMedia
                        component='video'
                        image={item.external.iconUrl}
                        className='max-w-[280px] sm:max-w-[240px] rounded-[8px] mx-auto'
                        autoPlay
                        loop
                        muted
                      />
                    ) : (
                      <CardMedia image={item.external.iconUrl} className='h-[280px] sm:h-[240px] rounded-[8px]' />
                    )}
                    <div className='absolute top-3 left-3'>
                      <ChipItemRarity rarity={item.external.rarity as RarityType} />
                    </div>
                  </div>
                  <Grid container spacing={1} className='flex-col mt-3'>
                    <Grid item className='flex items-center justify-between'>
                      <div className='font-semibold text-sm'>Price:</div>
                      <div className='font-bold'>{item.sale?.price} MATIC</div>
                    </Grid>
                    <Grid item className='flex items-center justify-between'>
                      <div className='font-semibold text-sm'>Owner:</div>
                      <div className='font-bold'>{shorten(item.ownerAddress)}</div>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </InfiniteSlider>
          </div>
        ) : (
          <div className='flex justify-center my-10'>
            <ResponsiveContainer
              carouselRef={carousel}
              render={(parentWidth, carouselRef) => {
                return (
                  <StackedCarousel
                    ref={carouselRef}
                    data={items}
                    carouselWidth={parentWidth}
                    slideWidth={300}
                    height={460}
                    slideComponent={Slide}
                    maxVisibleSlide={5}
                    currentVisibleSlide={5}
                    useGrabCursor={true}
                  />
                );
              }}
            />
          </div>
        )}

        <div className='flex justify-center'>
          <Link to='/marketplace'>
            <DesignButton>EXPLORE NOW</DesignButton>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default MarketplaceBanner;
