import { InfoOutlined } from '@mui/icons-material';
import { Checkbox, FormControl, InputAdornment, TextField, Tooltip } from '@mui/material';
import { DesignButton, InputPassword } from 'components';
import { useSnackbar } from 'notistack';
import { parse } from 'qs';
import { useState } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { GoogleLoginResponse } from 'react-google-login';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { accountService, trackerService } from 'services';
import { isEmail, isUsername } from 'utils/validate';
import { LoginSocial, TabSocial, TabVerify } from '.';

type TabAccountCreateProps = {
  onClose: () => void;
  onSwitch: () => void;
};

const TabAccountCreate = ({ onClose, onSwitch }: TabAccountCreateProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { refCode } = parse(location.search, { ignoreQueryPrefix: true });
  const { isLoggedIn, address } = useSelector(profileSelector);

  const { control, handleSubmit, watch } = useForm();
  const { email, password } = watch();

  const [registerType, setRegisterType] = useState<number>(10);
  const [registerData, setRegisterData] = useState<GoogleLoginResponse | ReactFacebookLoginInfo | AppleLoginResponse>();
  const [isVerify, setIsVerify] = useState(false);

  const { mutate, isLoading } = useMutation(accountService.registerOrLoginSocial, {
    onSuccess: () => {
      setIsVerify(true);
      enqueueSnackbar('Activation code has been sent');
      trackerService.trackPixel({ action: 3 });
    },
    onSettled: () => {
      if (isLoggedIn) {
        accountService.getProfile({ address }).then((profile) => {
          dispatch(signIn(profile));
        });
      }
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutate({
        ...(values as AccountRegisterOrLoginSocialType),
        address,
        registerType,
      });
    })();
  };

  return registerType !== 10 ? (
    <TabSocial
      registerType={registerType!}
      registerData={registerData!}
      onBack={() => setRegisterType(10)}
      onClose={onClose}
    />
  ) : isVerify ? (
    <TabVerify email={email} onBack={() => setIsVerify(false)} />
  ) : (
    <div className='flex flex-col gap-[24px]'>
      <Controller
        name='email'
        defaultValue=''
        control={control}
        rules={{
          required: 'Email is required',
          validate: {
            pattern: (value) => isEmail(value) || 'Email is not valid',
          },
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Email</label>
            <TextField {...field} type='email' error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name='username'
        defaultValue=''
        control={control}
        rules={{
          required: 'Username is required',
          minLength: { value: 6, message: 'Username is 6 characters minimum' },
          validate: {
            pattern: (value) => isUsername(value) || 'Username is not valid',
          },
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Username</label>
            <TextField
              {...field}
              error={invalid}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip title={`Only letters (a-z) and numbers (0-9) are accepted`}>
                      <InfoOutlined className='cursor-pointer text-black/50' />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
      />
      <Controller
        name='password'
        defaultValue=''
        control={control}
        rules={{
          required: 'Password is required',
          minLength: { value: 6, message: 'Password is 6 characters minimum' },
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Password</label>
            <InputPassword {...field} error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name='passwordConfirm'
        defaultValue=''
        control={control}
        rules={{
          required: 'Confirm Password is required',
          validate: {
            match: (value) => value === password || 'Confirm Password does not match',
          },
        }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Confirm Password</label>
            <InputPassword {...field} error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name='refCode'
        defaultValue={refCode ?? ''}
        control={control}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Referral Code (Optional)</label>
            <TextField {...field} type='text' error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />
      <div className='flex sm:items-center items-start'>
        <Checkbox size='small' defaultChecked style={{ padding: 0, marginRight: 4 }} />
        <span className='text-sm'>
          Read and Agree{' '}
          <a href='/' target='_blank' className='underline' rel='noreferrer'>
            Term and Condition
          </a>
        </span>
      </div>
      <div className='flex justify-center'>
        <DesignButton className='px-[48px]' loading={isLoading} onClick={handleClickSubmit}>
          CREATE
        </DesignButton>
      </div>

      <LoginSocial title='or Sign Up with' onTypeChange={setRegisterType} onDataChange={setRegisterData} />

      <div className='flex sm:flex-row flex-col justify-center text-center text-[14px]'>
        Already registered account?
        <span onClick={onSwitch} className='underline cursor-pointer ml-2'>
          Active account
        </span>
      </div>
    </div>
  );
};

export default TabAccountCreate;
