const CloudAnimate = () => {
  return (
    <div className='sm:block' style={{ display: 'none' }}>
      <img
        src={require('assets/images/metaverse-icon-1.png').default}
        className='absolute top-[160px] left-[0px]'
        data-aos='fade-up-right'
        data-aos-duration='800'
      />
      <img
        src={require('assets/images/metaverse-icon-2.png').default}
        className='absolute top-[320px] right-[-60px]'
        style={{ right: -60 }}
        data-aos='fade-up-left'
        data-aos-duration='800'
      />
    </div>
  );
};

export default CloudAnimate;
