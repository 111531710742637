import { DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import { CloseButton } from 'components';
import { useTabs } from 'hooks';
import { TabType } from 'hooks/useTabs';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { RegisterContext } from '.';
import { TabAccountActive, TabAccountCreate, TabAccountLinking } from './components';

const PopupRegister = ({ onClose }: PopupController) => {
  const { email, emailVerified } = useSelector(profileSelector);

  const [isCreate, setIsCreate] = useState(true);

  const tabs = ([] as TabType[])
    .concat([{ code: 'linking', label: 'Link Existing Account', component: <TabAccountLinking onClose={onClose} /> }])
    .concat([
      {
        code: 'account',
        label: isCreate ? 'Create Game Account' : 'Active Account',
        component: isCreate ? (
          <TabAccountCreate onClose={onClose} onSwitch={() => setIsCreate((prev) => !prev)} />
        ) : (
          <TabAccountActive onSwitch={() => setIsCreate((prev) => !prev)} />
        ),
      },
    ]);

  const [activeTab, onTabChange] = useTabs(tabs);

  useEffect(() => {
    if (email && !emailVerified) {
      onTabChange({} as SyntheticEvent<Element, Event>, 'account');
      setIsCreate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RegisterContext.Provider value={{ onTabChange, onClose }}>
      <>
        <DialogTitle>{tabs.find((item) => item.code === activeTab)?.label}</DialogTitle>
        <DialogContent>
          <Tabs
            value={activeTab}
            onChange={onTabChange}
            textColor='inherit'
            classes={{ flexContainer: 'justify-center border-b' }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.code}
                className='w-[120px] sm:w-[180px]'
                style={{ wordBreak: 'break-word' }}
                label={tab.label}
                value={tab.code}
              />
            ))}
          </Tabs>
          {tabs.map((tab) => (
            <div key={tab.code} hidden={tab.code !== activeTab} className='mt-[24px]'>
              {tab.component}
            </div>
          ))}
        </DialogContent>
        <CloseButton onClick={onClose} />
      </>
    </RegisterContext.Provider>
  );
};

export default PopupRegister;
