import { client } from './axios';

const fetchSubmissions = (params?: PaginateParamsType): Promise<SubmissionPaginateType> =>
  client.get(`/market-apis/api/submission`, { params });

const getSubmission = ({ id }: { id: string }): Promise<SubmissionType> =>
  client.get(`/market-apis/api/submission/${id}`);

const createVote = ({ id }: { id: string }): Promise<any> => client.post(`/market-apis/api/votes/${id}`);

export default {
  fetchSubmissions,
  getSubmission,

  createVote,
};
