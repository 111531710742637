import { client } from './axios';

const BASE_URL_USER = '/account-apis/api';

const login = (body: AccountLinkingType): Promise<any> => client.post(`${BASE_URL_USER}/profile/check-password`, body);
const getProfile = ({ address }: { address: string | undefined }): Promise<any> =>
  client.get(`${BASE_URL_USER}/profile/${address}`);
const updateProfile = (body: AccountUpdateType): Promise<any> => client.put(`${BASE_URL_USER}/profile`, body);

const linkAccount = (body: AccountLinkingType): Promise<any> =>
  client.post(`${BASE_URL_USER}/profile/link-to-account`, body);
const verifyAccount = (body: AccountVerifyType): Promise<any> =>
  client.post(`${BASE_URL_USER}/profile/verify-email`, body);
const registerOrLoginSocial = (body: AccountRegisterOrLoginSocialType): Promise<any> =>
  client.post(`${BASE_URL_USER}/profile/register-to-game`, body);

const resendCode = (body: { email: string }): Promise<any> => client.post(`${BASE_URL_USER}/profile/resend-code`, body);
const subscribe = (body: { email: string }): Promise<any> => client.post(`${BASE_URL_USER}/subscribe`, body);
const getInvited = (): Promise<AccountInvitedType[]> => client.get(`${BASE_URL_USER}/profile/get-invited`);

const forgotPasswordSendEmail = (body: AccountForgotPasswordSendEmailType): Promise<any> =>
  client.post(`${BASE_URL_USER}/profile/forgot-password/send-email`, body);
const forgotPasswordCheck = (body: AccountForgotPasswordCheckType): Promise<any> =>
  client.post(`${BASE_URL_USER}/profile/forgot-password/check`, body);
const forgotPasswordReset = (body: AccountForgotPasswordResetType): Promise<any> =>
  client.post(`${BASE_URL_USER}/profile/forgot-password/reset`, body);

export default {
  login,
  getProfile,
  updateProfile,

  linkAccount,
  verifyAccount,
  registerOrLoginSocial,

  resendCode,
  subscribe,
  getInvited,

  forgotPasswordSendEmail,
  forgotPasswordCheck,
  forgotPasswordReset,
};
