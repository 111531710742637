import { LatLngBoundsLiteral } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useMemo, useState } from 'react';
import { ImageOverlay, MapContainer, useMap } from 'react-leaflet';
import { companies, data, landData } from './data';
import ImageOverlayContainer from './ImageOverlayContainer';
import PolygonContainer from './PolygonContainer';
import PopupSideBar from './PopupSideBar';

const innerBounds: LatLngBoundsLiteral = [
  [0, 0],
  [100, 300],
];

function SetBoundsImage() {
  const [bounds, setBounds] = useState(innerBounds);
  const map = useMap();

  const innerHandlers = useMemo(
    () => ({
      click() {
        setBounds(innerBounds);
        map.fitBounds(innerBounds);
      },
    }),
    [map],
  );

  return (
    <ImageOverlay
      url='https://d1cqw9qrof1e8g.cloudfront.net/map.webp'
      opacity={1}
      zIndex={10}
      eventHandlers={innerHandlers}
      bounds={bounds}
    />
  );
}

const Map = () => {
  const [dataDrawer, setDataDrawer] = useState({
    isOpen: false,
    dataPopup: {
      name: '',
      description: '',
      image: [''],
    },
  });

  return (
    <div id='interactive-map'>
      <MapContainer
        className='w-[100vw] z-[1] relative bg-[#8aac85]'
        style={{ height: 'calc(100vh - 80px)' }}
        attributionControl={false}
        center={[66, 150]}
        zoom={3}
        minZoom={3}
        maxZoom={4}
        maxBounds={[
          [-5, -5],
          [105, 305],
        ]}
        scrollWheelZoom={false}
      >
        <SetBoundsImage />

        {data.map((item: any) => (
          <PolygonContainer
            key={item.id}
            id={item.id}
            setIsOpen={setDataDrawer}
            position={item.position}
            data={item.popupData}
          />
        ))}

        {landData.map((item: any) => (
          <PolygonContainer
            key={item.id}
            id={item.id}
            color={item.color}
            setIsOpen={setDataDrawer}
            fillOpacity={item.fillOpacity}
            position={item.position}
            data={item.popupData}
          />
        ))}

        {companies.map((item: any) => (
          <ImageOverlayContainer key={item.id} bounds={item.position} image={item.logo} type={item.type} />
        ))}
        <PopupSideBar data={dataDrawer} setIsOpen={setDataDrawer} />
      </MapContainer>
    </div>
  );
};

export default Map;
