import { Container } from '@mui/material';
import { useWindowDimensions } from 'hooks';
import merge from 'utils/merge';
import stylesHome from 'views/Home/components/Home.module.scss';
import styles from './Events.module.scss';

const TopLogo = () => {
  const { isMobile } = useWindowDimensions();
  return (
    <Container className='md:mt-[47px] mt-[16px] md:pb-[24px] flex flex-col justify-center'>
      <div className='flex justify-center md:pb-[32px] pb-[16px] relative w-full'>
        <div>
          <div className={styles.header}>Events</div>
          <div className='text-xl text-justify text-neutral-500'>Keep up with latest events</div>
          {isMobile === false && (
            <>
              <img
                src={require('../assets/icons/sun.svg').default}
                className={merge(
                  stylesHome.animateUpDown,
                  'absolute md:right-[21%] lg:right-[28%] xl:right-[32%] top-[-24px]',
                )}
              />
              <img
                src={require('../assets/icons/star.svg').default}
                className={merge(
                  stylesHome.animateUpDown,
                  'absolute md:left-[30%] right:left-[33%] xl:left-[35%] top-[0px]',
                )}
              />
              <img
                src={require('../assets/icons/star.svg').default}
                className={merge(stylesHome.animateUpDown, 'absolute md:right-[13%] xl:right-[25%] top-[29px]')}
              />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default TopLogo;
