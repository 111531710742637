const BirdAnimate = () => {
  return (
    <div className='md:block hidden absolute top-[180px] left-[-10px]'>
      <div data-aos='fade-up-right' data-aos-duration='1000'>
        <img src={require('../assets/icons/animate-bird.png').default} />
        <img src={require('../assets/icons/animate-cloud.png').default} className='absolute top-[70px]' />
      </div>
    </div>
  );
};

export default BirdAnimate;
