import { Button, CardMedia, Dialog, Grid, Pagination, Paper } from '@mui/material';
import { useSearch, useValidNetwork } from 'hooks';
import { parse } from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { submissionService, walletService } from 'services';
import { PopupVote } from '.';

const VoteList = () => {
  const { validNetwork } = useValidNetwork();
  const { isLoggedIn } = useSelector(profileSelector);
  const { subId } = parse(window.location.search, { ignoreQueryPrefix: true });

  const [dataSearch, onSearchChange] = useSearch({ orderBy: 'voteAmount', desc: true });

  const { data } = useQuery(
    ['submissionService.fetchSubmissions', dataSearch],
    () => submissionService.fetchSubmissions(dataSearch),
    { keepPreviousData: true },
  );
  const { items = [], currentPage, pages: totalPage } = data ?? {};

  const [isOpenVote, setOpenVote] = useState(false);
  const [chosenItem, setChosenItem] = useState<SubmissionType>();

  useEffect(() => {
    if (subId) {
      submissionService.getSubmission({ id: subId as string }).then((item) => {
        setOpenVote(true);
        setChosenItem(item);
      });
    }
  }, [subId]);

  useEffect(() => {
    if (!isLoggedIn) {
      setOpenVote(false);
    }
  }, [isLoggedIn]);

  return (
    <>
      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item xl={12 / 5} lg={3} md={4} sm={6} xs={12} key={item.id}>
            <Paper
              elevation={0}
              className={'hover:shadow-card rounded-[8px] p-[24px] relative text-center'}
              sx={{ '&:hover .VOTE': { display: 'flex !important' } }}
            >
              <div className='mb-3'>
                <CardMedia image={item.imageUrl} className='h-[240px] rounded-[8px]' />
              </div>
              <div className='font-black text-2xl max-line-1'>{item.name}</div>
              <div className='font-semibold'>
                Vote: <span className='text-xl text-secondary-main'>{item.voteAmount}</span>
              </div>

              <div className={'hidden items-center justify-center rounded-[8px] VOTE absolute inset-0 bg-black/10'}>
                {isLoggedIn ? (
                  <Button
                    color='secondary'
                    className='bg-white hover:brightness-90'
                    onClick={() => {
                      setOpenVote(true);
                      setChosenItem(item);
                    }}
                  >
                    Vote now
                  </Button>
                ) : (
                  <Button onClick={() => validNetwork(walletService.connectWallet)}>Connect Wallet</Button>
                )}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <div className='flex justify-center my-8'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog open={isOpenVote} fullWidth maxWidth='sm'>
        <PopupVote item={chosenItem!} onClose={() => setOpenVote(false)} />
      </Dialog>
    </>
  );
};

export default VoteList;
