import { CardMedia, DialogContent, DialogTitle, Grid } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { ethersContract, web3, web3Contract } from 'contracts';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { glipService, marketService, queryClient, trackerService } from 'services';
import { getPolygonFee } from 'utils/common';

type PopupProps = PopupController & {
  item: ItemType;
};

const PopupCancel = ({ item, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isGlip, address } = useSelector(profileSelector);
  const { chainId: appChainId, marketplaceAddress } = useSelector(systemSelector);

  const { mutate, isLoading } = useMutation(
    async () => {
      const maxFeeForFast = (await getPolygonFee(+appChainId)) as number;
      const priceInWei = web3.utils.toWei(item.sale?.price.toString()!, 'ether');

      if (isGlip) {
        const glipWallet = await glipService.getGlipWallet();
        const signer = await glipWallet.getSigner();
        const trans = await ethersContract(marketplaceAddress, 'MARKET').populateTransaction['cancelSale'](
          item.type,
          [address, item.nftContract, item.sale?.paymentToken?.contractAddress],
          [item.tokenId, priceInWei, item.sale?.saltNonce, item.amount],
          item.sale?.signedSignature,
        );
        await signer.sendTransaction(trans);
      } else {
        await web3Contract(marketplaceAddress, 'MARKET')
          .methods.cancelSale(
            item.type,
            [address, item.nftContract, item.sale?.paymentToken?.contractAddress],
            [item.tokenId, priceInWei, item.sale?.saltNonce, item.amount],
            item.sale?.signedSignature,
          )
          .send({
            from: address,
            gasPrice: Math.ceil(maxFeeForFast),
          });
      }

      return marketService.deleteSale({ id: item.sale?.id! });
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Cancel listing successfully');
        queryClient.invalidateQueries('marketService.getItemByTokenId');
        queryClient.invalidateQueries('marketService.fetchItems');
        queryClient.invalidateQueries('marketService.fetchSales');
        trackerService.trackPixel({ action: 7 });
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    mutate();
  };

  return (
    <>
      <DialogTitle>Cancel listing</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item sm={5} xs={12}>
            {item.external.iconType === 'mp4' ? (
              <CardMedia
                component='video'
                image={item.external.iconUrl}
                className='max-w-[240px] rounded-[8px] mx-auto'
                autoPlay
                loop
                muted
              />
            ) : (
              <CardMedia image={item.external.iconUrl} className='h-[240px] rounded-[8px]' />
            )}
          </Grid>
          <Grid item sm={7} xs={12} className='flex flex-col gap-6'>
            <div>
              <div className='text-neutral-400'>You are about to unlist your item</div>
              <div className='font-bold'>#{item.tokenId}</div>
            </div>

            <div>
              <div className='text-neutral-400'>Price</div>
              <div className='font-bold'>
                {item.sale?.price.toLocaleString()} {item.sale?.paymentToken?.symbol}
              </div>
            </div>

            <div className='flex-1 flex flex-col justify-end w-full sm:w-40'>
              <DesignButton
                fullWidth
                variant='contained'
                color='secondary'
                loading={isLoading}
                onClick={handleClickSubmit}
              >
                {isLoading ? 'Processing' : 'Confirm'}
              </DesignButton>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <CloseButton onClick={onClose} disabled={isLoading} />
    </>
  );
};

export default PopupCancel;
