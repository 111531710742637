import { Dialog } from '@mui/material';
import { DesignButton } from 'components';
import { useValidNetwork } from 'hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { walletService } from 'services';
import { PopupBuy, PopupCancel, PopupListing, PopupTransfer } from 'views/ItemView/popups';

const BoxPrice = ({ item }: { item: ItemType }) => {
  const { validNetwork } = useValidNetwork();
  const { isLoggedIn, address } = useSelector(profileSelector);

  const isOwner = isLoggedIn && address === item.ownerAddress;
  const isMinted = item.status === 1;

  const [isOpenBuy, setOpenBuy] = useState(false);
  const [isOpenTransfer, setOpenTransfer] = useState(false);
  const [isOpenListing, setOpenListing] = useState(false);
  const [isOpenCancel, setOpenCancel] = useState(false);

  useEffect(() => {
    if (!isLoggedIn || !item.listedOnMarket) {
      setOpenTransfer(false);
      setOpenListing(false);
      setOpenCancel(false);
      setOpenBuy(false);
    }
  }, [isLoggedIn, item.listedOnMarket]);

  return (
    <div className='flex-1 flex gap-4'>
      {isOwner && !isMinted && (
        <DesignButton color='info' className='w-40' onClick={() => validNetwork(() => setOpenTransfer(true))}>
          Mint NFT
        </DesignButton>
      )}
      {item.listedOnMarket ? (
        <>
          {isOwner ? (
            <DesignButton color='secondary' className='w-40' onClick={() => validNetwork(() => setOpenCancel(true))}>
              Cancel Listing
            </DesignButton>
          ) : isLoggedIn ? (
            <DesignButton color='secondary' className='w-40' onClick={() => validNetwork(() => setOpenBuy(true))}>
              Buy Now
            </DesignButton>
          ) : (
            <DesignButton className='w-40' onClick={() => validNetwork(walletService.connectWallet)}>
              Connect Wallet
            </DesignButton>
          )}
        </>
      ) : (
        <>
          {isLoggedIn ? (
            isOwner ? (
              <DesignButton color='secondary' className='w-40' onClick={() => validNetwork(() => setOpenListing(true))}>
                Put On Market
              </DesignButton>
            ) : null
          ) : (
            <DesignButton className='w-40' onClick={() => validNetwork(walletService.connectWallet)}>
              Connect Wallet
            </DesignButton>
          )}
        </>
      )}

      <Dialog open={isOpenTransfer} fullWidth maxWidth='sm'>
        <PopupTransfer item={item} onClose={() => setOpenTransfer(false)} />
      </Dialog>
      <Dialog open={isOpenListing} fullWidth maxWidth='sm'>
        <PopupListing item={item} onClose={() => setOpenListing(false)} />
      </Dialog>
      <Dialog open={isOpenCancel} fullWidth maxWidth='sm'>
        <PopupCancel item={item} onClose={() => setOpenCancel(false)} />
      </Dialog>
      <Dialog open={isOpenBuy} fullWidth maxWidth='sm'>
        <PopupBuy item={item} onClose={() => setOpenBuy(false)} />
      </Dialog>
    </div>
  );
};

export default BoxPrice;
