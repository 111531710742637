import { Dialog, Drawer, Skeleton } from '@mui/material';
import { DesignButton } from 'components';
import { useWindowDimensions } from 'hooks';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useState } from 'react';

const Popup = ({
  isOpenPopup,
  setIsOpenPopup,
  dataPopup,
}: {
  isOpenPopup: boolean;
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  dataPopup: any;
}) => {
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const { isMobile } = useWindowDimensions();
  const Content = () => {
    return (
      <div className={`${isMobile ? 'p-6' : 'p-0'}`}>
        <div className='flex flex-col '>
          <div className='flex flex-row'>
            <div className={`w-2 h-12 rounded-2xl mr-4`} style={{ background: `${dataPopup.category.color}` }}></div>
            <div className='flex-col'>
              <div
                className='whitespace-nowrap font-bold md:text-sm text-xs'
                style={{ color: `${dataPopup.category.color}` }}
              >
                {dataPopup.category.name}
              </div>
              <div className='font-extrabold md:text-xl text-base'>{dataPopup.title}</div>
            </div>
          </div>

          <div className='pt-6 flex flex-row'>
            <div className='rounded-2xl w-full bg-[#F6F6F6]'>
              <div className='h-[60px] p-3 flex flex-row'>
                <div className='text-xs text-center mx-auto pt-[5px]'>
                  <span className='font-bold text-[#BDC8CC]'>Start Date</span>
                  <span className='font-bold pl-2 md:text-xl text-base'>
                    {DateTime.fromJSDate(dataPopup.start).toFormat('dd MMM')}
                  </span>
                </div>
                <div className={'w-[1px] h-full bg-[#DEDEDE] mx-auto'}></div>
                <div className='text-xs text-center pt-[5px] mx-auto'>
                  <span className='font-bold text-[#BDC8CC]'>End Date</span>
                  <span className='font-bold pl-2 md:text-xl text-base'>
                    {DateTime.fromJSDate(dataPopup.end).toFormat('dd MMM')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <img
            src={dataPopup.bannerUrl}
            alt='banner'
            onLoad={() => setIsLoadingImg(true)}
            className={`w-full ${isLoadingImg === false && 'hidden'}} rounded-2xl mt-6`}
          />
          {isLoadingImg ? (
            <></>
          ) : (
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant='rectangular'
              width='100%'
              className='h-[180px] rounded-2xl'
            />
          )}

          <div className='md:text-sm text-xs mt-6 truncate whitespace-pre-line overflow-hidden text-ellipsis my-6 max-h-[138px] text-black'>
            {dataPopup.detail}
          </div>
          <div
            className={`border-dashed border-2 rounded-2xl border-[#DEDEDE] ${
              dataPopup.prize === '' && dataPopup.howToWin === '' ? 'hidden  ' : ''
            } mb-6 w-full`}
          >
            <div
              className={`flex flex-row mx-4 md:mx-6 ${dataPopup.prize === '' && 'hidden'} md:mt-6 ${
                dataPopup.howToWin === '' && 'md:mb-6 mb-4'
              }  mt-4`}
            >
              <img
                src={require('../assets/icons/ic_prize.svg').default}
                alt='bell'
                className='md:w-14 md:h-14 w-12 h-12 cursor-pointer'
              />
              <div className='flex-col pt-2 ml-4'>
                <div className='whitespace-nowrap text-left font-bold md:text-sm text-xs'>Prize</div>
                <div className='md:text-sm text-xs md:w-[306px] w-full whitespace-pre-line'>{dataPopup.prize}</div>
              </div>
            </div>
            <div className={`flex flex-row ${dataPopup.howToWin === '' && 'hidden'} md:mx-6 md:my-6 mx-4 mt-4`}>
              <img
                src={require('../assets/icons/ic_wining.svg').default}
                alt='bell'
                className='md:w-14 md:h-14 w-12 h-12 cursor-pointer'
              />
              <div className={`flex-col pt-2 ml-4`}>
                <div className='whitespace-nowrap font-bold md:text-sm text-xs'>Winning</div>
                <div className=' md:text-sm text-xs md:w-[306px] w-full h-10 whitespace-pre-line break-all'>
                  {dataPopup.howToWin}
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-row pb-1 justify-between w-full'>
            <div className='w-[35%] pr-4'>
              <DesignButton
                fullWidth
                color='inherit'
                onClick={(e) => {
                  setIsOpenPopup(false);
                  setIsLoadingImg(false);
                }}
              >
                CLOSE
              </DesignButton>
            </div>
            <div className={`w-[65%] ${dataPopup.externalUrl === '' && 'hidden'}`}>
              <DesignButton
                fullWidth
                color='info'
                onClick={(e) => {
                  window.open(dataPopup.externalUrl);
                }}
              >
                SEE MORE
              </DesignButton>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {isMobile ? (
        <Drawer
          anchor={'bottom'}
          open={isOpenPopup}
          BackdropProps={{ sx: { background: 'rgba(219, 241, 255, 0.7)' } }}
          PaperProps={{ sx: { borderTopLeftRadius: '24px', borderTopRightRadius: '24px' } }}
          onClose={() => {
            setIsOpenPopup(false);
            setIsLoadingImg(false);
          }}
        >
          <Content />
        </Drawer>
      ) : (
        <Dialog
          open={isOpenPopup}
          onClose={() => setIsOpenPopup(false)}
          hideBackdrop
          PaperProps={{
            sx: {
              margin: 0,
              width: '30rem',
              background: 'white',
              borderRadius: '24px',
              boxShadow: '0px 12px 16px rgba(143, 209, 236, 0.25)',
              padding: 10,
            },
          }}
        >
          <Content />
        </Dialog>
      )}
    </>
  );
};
export default Popup;
