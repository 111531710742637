import { AppBar, Button, Container, Grid, TextField, Toolbar } from '@mui/material';
import { DesignButton } from 'components';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { accountService } from 'services';
import { isEmail } from 'utils/validate';

export const SOCIALS = [
  {
    url: 'https://www.facebook.com/MyMetaFarmOfficial',
    icon: require('assets/icons/socials/facebook.svg').default,
  },
  {
    url: 'https://instagram.com/MyMetaFarmOfficial',
    icon: require('assets/icons/socials/instagram.svg').default,
  },
  {
    url: 'https://twitter.com/MyMetaFarm',
    icon: require('assets/icons/socials/twitter.svg').default,
  },
  {
    url: 'https://discord.gg/MyMetaFarm',
    icon: require('assets/icons/socials/discord.svg').default,
  },
  {
    url: 'https://t.me/MyMetaFarm',
    icon: require('assets/icons/socials/telegram.svg').default,
  },
  {
    url: 'https://www.youtube.com/channel/UCQ_NUT5gSaYfEDq61YbhDlA',
    icon: require('assets/icons/socials/youtube.svg').default,
  },
];

export const EmailSubscribe = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState('');

  const handleClickSubscribe = () => {
    if (isEmail(email.trim())) {
      accountService.subscribe({ email }).then(() => {
        enqueueSnackbar('You have been successfully subscribed');
      });
    }
    setEmail('');
  };

  return (
    <div className='flex items-start gap-3'>
      <TextField
        size='small'
        placeholder='Enter your email'
        InputProps={{ className: 'bg-white text-black h-full' }}
        value={email}
        onChange={(event) => setEmail(event.target.value.trim())}
      />
      <DesignButton size='medium' onClick={handleClickSubscribe}>
        Subscribe
      </DesignButton>
    </div>
  );
};

const Footer = () => {
  return (
    <AppBar component='footer' position='static' elevation={0} color='transparent' className='relative z-[1]'>
      <div
        style={{ background: `url(${require('assets/images/footer-background.png').default}) no-repeat bottom` }}
        className='flex items-end md:h-[720px] h-[320px] bg-cover overflow-hidden'
      >
        <Container maxWidth='md' className='relative lg:py-[160px] py-[40px]'>
          <img
            src={require('assets/images/footer-character.png').default}
            className='absolute left-[-240px] bottom-[80px]'
            style={{ left: -240 }}
          />
          <img
            src={require('assets/images/footer-tree-1.png').default}
            className='absolute left-[-580px] bottom-[20px] w-[400px]'
            style={{ left: -580 }}
          />
          <img
            src={require('assets/images/footer-tree-3.png').default}
            className='absolute right-[-580px] bottom-[20px] w-[600px]'
            style={{ right: -580 }}
          />
          <Grid container spacing={4} className='md:text-white'>
            <Grid item md={6.5} className='flex flex-col items-start gap-3'>
              <div className='font-black'>STAY IN THE LOOP</div>
              <div className='text-sm'>
                Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and
                tricks for navigating My Meta Farm.
              </div>
              <EmailSubscribe />
            </Grid>
            <Grid item md={5.5} className='flex flex-col items-start gap-3'>
              <div className='font-black'>JOIN OUR COMMUNITY</div>
              <div className='flex flex-wrap gap-[10px]'>
                {SOCIALS.map((item, index) => (
                  <a key={index} href={item.url} target='_blank' rel='noreferrer'>
                    <Button
                      variant='text'
                      color='inherit'
                      classes={{ root: 'bg-green-500 hover:brightness-90 w-[48px] h-[48px]' }}
                    >
                      <img src={item.icon} />
                    </Button>
                  </a>
                ))}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Toolbar className='bg-green-500'>
        <Container>
          <div className='font-bold text-white'>© My Meta Farm 2022</div>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
