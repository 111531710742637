import { Button, Container } from '@mui/material';
import { HomeFooterAnimate } from 'views/Home/animations';
import { EmailSubscribe, SOCIALS } from './Footer';

const FooterHome = () => {
  return (
    <div style={{ background: 'linear-gradient(180deg, #F4E9FE 0%, #FFF3E3 100%)' }}>
      <Container className='py-[60px] relative justify-center'>
        <div className='flex flex-col items-center'>
          <div
            style={{ background: `url(${require('assets/images/footer-home-noti.png').default})` }}
            className='flex flex-col items-center w-[500px] h-[360px]'
          >
            <div className='text-center mt-[140px] mb-[20px]'>
              See latest update about
              <br />
              My Meta Farm's events and news
            </div>
            <EmailSubscribe />
          </div>

          <div className='flex flex-wrap gap-2 sm:gap-3'>
            {SOCIALS.map((item, index) => (
              <a key={index} href={item.url} target='_blank' rel='noreferrer'>
                <Button
                  variant='text'
                  color='inherit'
                  classes={{ root: 'bg-[#5DA8EC] hover:brightness-90 w-[48px] h-[48px]' }}
                >
                  <img src={item.icon} />
                </Button>
              </a>
            ))}
          </div>
        </div>
        <HomeFooterAnimate />
      </Container>
      <div className='text-center py-[20px]'>© My meta farm 2022</div>
    </div>
  );
};

export default FooterHome;
