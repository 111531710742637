import { Container, List, ListItemButton } from '@mui/material';
import { styled } from '@mui/system';
import { InfiniteSlider } from 'components';
import { Fragment, useRef, useState } from 'react';
import { merge } from 'utils/common';
import styles from './Home.module.scss';

const StyledListItem = styled(ListItemButton)({
  fontWeight: 900,
  fontSize: 24,
  padding: '20px 16px',
  color: '#18446E',
  borderBottom: '1px solid #B2D8CF',
});

const runner = ['METAVERSE WORLD', 'CREATE 3D AVATAR', 'DIGITAL FASHION', 'MINIGAMES', 'SOCIALIZING', 'CREATIVE TOOLS'];

const nav = [
  { label: 'METAVERSE WORLD', image: require('../assets/videos/metaverse-1.gif').default },
  { label: 'CREATE 3D AVATAR', image: require('../assets/videos/metaverse-2.gif').default },
  { label: 'DIGITAL FASHION', image: require('../assets/videos/metaverse-3.gif').default },
  { label: 'MINIGAMES', image: require('../assets/videos/metaverse-1.gif').default },
  { label: 'SOCIALIZING', image: require('../assets/videos/metaverse-2.gif').default },
  { label: 'CREATIVE TOOLS', image: require('../assets/videos/metaverse-3.gif').default },
];

const WhatAreTheDifferences = () => {
  const slider = useRef<InfiniteSlider>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div style={{ background: 'linear-gradient(160deg, #F3FFF3 15.28%, #D2EAF0 85.34%)' }}>
      <Container className='py-[60px]'>
        <div className='relative'>
          <div className={styles.landingText}>MY META FARM</div>
          <div className={merge(styles.header, 'absolute left-0 right-0 bottom-4')}>WHAT ARE THE DIFFERENCES?</div>
        </div>

        <div className='flex flex-wrap justify-center items-center gap-10 md:mt-20'>
          <List className='w-[400px]'>
            {nav.map((item, index) => (
              <StyledListItem
                key={index}
                selected={index === activeIndex}
                onClick={() => {
                  slider.current?.slickGoTo?.(index - 1);
                }}
              >
                {item.label}
              </StyledListItem>
            ))}
          </List>

          <div className='max-w-[1000px] hidden'>
            <InfiniteSlider
              ref={slider}
              {...{ autoplay: true, dots: false, arrows: false }}
              beforeChange={(_, next) => setActiveIndex((next + 1) % nav.length)}
            >
              {nav.map((item, index) => (
                <div key={index} className='md:h-[480px] h-[320px] flex justify-center'>
                  <img src={item.image} className='border-[4px] border-white rounded-[12px]' />
                </div>
              ))}
            </InfiniteSlider>
          </div>
          <div>
            <iframe
              className='lg:w-[800px] lg:h-[480px] w-[320px] h-[420px]'
              src='https://www.youtube.com/embed/Ro5Os9TL3oI'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Container>

      <div className={styles.divider}>
        <div className={merge(styles.running, 'flex items-center gap-10 mr-10')}>
          {runner
            .concat(runner)
            .concat(runner)
            .map((text, index) => (
              <Fragment key={index}>
                <span className='text-2xl font-black text-blue-400 whitespace-nowrap'>{text}</span>
                <img src={require('../assets/icons/icon-star-1.png').default} />
              </Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default WhatAreTheDifferences;
