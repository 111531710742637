import { Container, Grid } from '@mui/material';
import { useWindowDimensions } from 'hooks';
import { merge } from 'utils/common';
import { BirdAnimate } from '../animations';
import styles from './Home.module.scss';

const items = [
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/26/26_hijab_icon.png' },
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/39/39_icon.png' },
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/17/17_icon.png' },
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/10/10_icon.png' },
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/38/38_icon.png' },
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/37/37_icon.png' },
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/12/12_icon.png' },
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/40/40_icon.png' },
  { image: 'https://d9hvi1ehclsbh.cloudfront.net/11/11_icon.png' },
];

const DigitalFashion = () => {
  const { isMobile } = useWindowDimensions();

  return (
    <div
      style={{
        background: 'linear-gradient(180deg, #F7FBFA 0%, #F0EBFF 50%, #EBE4FF 100%)',
        position: 'relative',
      }}
    >
      <Container className='py-[60px] z-10 relative'>
        <div className={styles.header}>Digital Fashion</div>
        <Container maxWidth='md' className='px-0'>
          <div className='text-base sm:text-xl text-neutral-500 text-center'>
            Freely design NFT items, craft unique outfits, create your fashion brand in the metaverse world. Or you can
            bring your own brand into the My Meta Farm metaverse.
          </div>
        </Container>
        <div className='flex flex-wrap items-start mt-8 sm:mt-20'>
          <img src={require('../assets/images/fashion-body.png').default} className='w-[560px] mx-auto' />
          <Grid container className='flex-1 justify-center md:min-w-[760px]'>
            {items.slice(0, isMobile ? 4 : 9).map((item, index) => (
              <Grid item key={index} className='md:m-[12px] m-[4px]'>
                <div
                  className={merge(
                    'flex justify-center items-center md:border-[4px] border-[2px] border-white',
                    'md:rounded-[12px] rounded-[4px] md:w-[210px] md:h-[210px] w-[140px] h-[140px]',
                  )}
                >
                  <img src={item.image} className='cursor-pointer hover:scale-110' />
                </div>
              </Grid>
            ))}
          </Grid>
          <BirdAnimate />
        </div>
      </Container>

      <Container className='py-[60px] z-10 relative'>
        <div className={styles.header}>Minigames</div>
        <Container maxWidth='md' className='px-0'>
          <div className='text-base sm:text-xl text-left text-neutral-500 sm:text-center'>
            With fun and diverse gameplays, minigames completely become a playground where citizens can relax, make new
            friends, and earn NFTs
          </div>
        </Container>

        <Grid container spacing={6} className='mt-2 sm:mt-10 justify-center'>
          {[
            { image: require('../assets/videos/minigame-1.gif').default },
            { image: require('../assets/videos/minigame-2.gif').default },
          ].map((item, index) => (
            <Grid item key={index}>
              <img
                src={item.image}
                className='border-[4px] border-white rounded-[12px] md:h-[400px] max-h-[320px] h-fit object-contain'
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      {!isMobile && (
        <img
          src={require('../assets/images/background-fashion.png').default}
          className={merge(styles.runningRight, 'absolute w-[200vw] h-full top-0 z-0')}
        />
      )}
    </div>
  );
};

export default DigitalFashion;
