import { Check } from '@mui/icons-material';
import { DesignButton } from 'components';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { RegisterContext } from 'views/PreRegister';

const TabVerifySuccess = () => {
  const context = useContext(RegisterContext);
  const { isLoggedIn } = useSelector(profileSelector);

  return (
    <div className='flex flex-col items-center gap-[24px]'>
      <div className='flex justify-center items-center bg-green-400 rounded-full w-[32px] h-[32px]'>
        <Check className='text-white' />
      </div>

      <div className='text-center'>You have successfully {isLoggedIn ? 'link' : 'create'} game account.</div>

      <div className='flex justify-center'>
        {!isLoggedIn ? (
          <DesignButton
            onClick={(event) => {
              context?.onTabChange(event, 'linking');
            }}
          >
            LINK ACCOUNT
          </DesignButton>
        ) : (
          <DesignButton
            color='inherit'
            onClick={() => {
              context?.onClose();
            }}
          >
            CLOSE
          </DesignButton>
        )}
      </div>
    </div>
  );
};

export default TabVerifySuccess;
