import { DialogContent, DialogTitle } from '@mui/material';
import { CloseButton } from 'components';
import { accountService } from 'services';
import { useSnackbar } from 'notistack';
import { stringify } from 'qs';
import { publicRoute } from 'routes';

type ResetPasswordPopupProps = PopupController & {
  email: string;
};

const ResetPasswordPopup = ({ onClose, email }: ResetPasswordPopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const url =
    window.location.origin + publicRoute.resetPassword.path + stringify({ code: '' }, { addQueryPrefix: true });

  const handleClickResend = () => {
    accountService.forgotPasswordSendEmail({ email, reset_url: url }).then(() => {
      enqueueSnackbar('Email has been sent');
    });
  };

  return (
    <>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <div className='text-[#0A1316] text-sm'>
          In order to reset your password, click the link in the email and create a new password on the page opens.
        </div>
        <div className='my-4'>
          <div className='text-[#717171] text-sm'>Your email:</div>
          <div className='text-[#0A1316] text-sm'>{email}</div>
        </div>
        <div className='flex items-center text-sm text-[#0A1316]'>
          Didn't receive email?
          <span onClick={handleClickResend} className='text-info-dark underline cursor-pointer ml-2'>
            Resend
          </span>
        </div>
      </DialogContent>
      <CloseButton onClick={onClose} />
    </>
  );
};

export default ResetPasswordPopup;
