const HomeFooterAnimate = () => {
  return (
    <div className='md:block hidden'>
      <img
        src={require('../assets/icons/animate-cloud.png').default}
        className='absolute top-[160px] left-[40px]'
        data-aos='fade-up-right'
        data-aos-duration='800'
      />
      <img
        src={require('../assets/icons/animate-cloud.png').default}
        className='absolute top-[300px] left-[150px] w-[100px]'
        data-aos='fade-up-right'
        data-aos-duration='800'
      />
      <img
        src={require('assets/images/footer-home-vrcharacter.png').default}
        className='absolute top-[160px] right-[-40px]'
        data-aos='fade-up-left'
        data-aos-duration='800'
      />
    </div>
  );
};

export default HomeFooterAnimate;
