import { CardMedia, Container } from '@mui/material';
import { Fragment } from 'react';
import { merge } from 'utils/common';
import stylesHome from 'views/Home/components/Home.module.scss';
import { VoteList } from './components';

const runner = ['CUSTOM DESIGN VOTING', 'CUSTOM CLOTHING', 'CREATE YOUR STYLE', 'NFT ITEMS', 'UNIQUE OUTFIT'];

const Vote = () => {
  return (
    <>
      <div className='bg-[#EEF1FB] pt-20 pb-40'>
        <Container>
          <CardMedia
            image={require('assets/images/vote-cover.png').default}
            className='h-[440px] flex flex-col items-center rounded-[16px]'
          >
            <img src={require('assets/images/vote-avatar.png').default} className='mb-[-30px] mt-[40px]' />
            <div className={merge(stylesHome.header, 'text-blue-200 scale-110')}>Custom Design Voting</div>
            <div className='text-xl font-bold text-neutral-500'>Time: 10/01/2022 - 10/02/2023</div>
            <div className='font-bold text-blue-200 hover:text-blue-300 cursor-pointer underline mt-3'>
              Rules & Reward
            </div>
          </CardMedia>
        </Container>
      </div>
      <div className={stylesHome.divider}>
        <div className={merge(stylesHome.running, 'flex items-center gap-10 mr-10')}>
          {runner
            .concat(runner)
            .concat(runner)
            .map((text, index) => (
              <Fragment key={index}>
                <span className='text-2xl font-black text-blue-400 whitespace-nowrap'>{text}</span>
                <img src={require('views/Home/assets/icons/icon-star-1.png').default} />
              </Fragment>
            ))}
        </div>
      </div>
      <div style={{ background: `linear-gradient(180deg, #D6EBFF 0%, #F4E9FE 100%)` }} className='relative py-20'>
        <div className='absolute top-0 left-0 right-0'>
          <img src={require('assets/images/vote-background.png').default} className='w-full' />
        </div>
        <Container className='relative z-10'>
          <div className={merge(stylesHome.header)}>Submissions</div>
          <VoteList />
        </Container>
      </div>
    </>
  );
};

export default Vote;
