import { Container } from '@mui/material';
import { useWindowDimensions } from 'hooks';
import { useState } from 'react';
import { merge } from 'utils/common';
import styles from './Home.module.scss';

const Create3dAvatar = () => {
  const { isMobile } = useWindowDimensions();
  const [array] = useState([1, 2, 3, 4].sort(() => 0.5 - Math.random()));

  return (
    <div
      style={{ background: `url(${require('../assets/images/background-3davatar.png').default})` }}
      className='bg-no-repeat bg-center bg-cover'
    >
      <Container className='py-[60px]'>
        <div className={styles.header}>Create 3D Avatar</div>
        <Container maxWidth='md' className='px-0'>
          <div className='text-base sm:text-xl text-neutral-500 text-center'>
            A 3D avatar that can represent you in the virtual world. Create an avatar and explore our metaverse. And you
            will be unique in the way you want to be.
          </div>
        </Container>

        <div className='flex justify-center md:mt-[160px]'>
          <div className='relative'>
            {isMobile ? (
              <div className='absolute bottom-[-20px] flex flex-wrap justify-center items-center gap-3'>
                <img src={require(`../assets/images/avatar-1.png`).default} className='w-[180px]' />
                <img src={require(`../assets/images/avatar-3.png`).default} className='w-[120px]' />
                <img src={require(`../assets/images/avatar-4.png`).default} className='w-[100px]' />
                <img src={require(`../assets/images/avatar-2.png`).default} className='w-[160px]' />
              </div>
            ) : (
              <>
                <img
                  src={require(`../assets/images/avatar-${array[0]}.png`).default}
                  className={merge(styles.animateUpDown, 'absolute top-[-80px] left-[-80px]')}
                />
                <img
                  src={require(`../assets/images/avatar-${array[1]}.png`).default}
                  className={merge(styles.animateUpDown, 'absolute top-[160px] left-[-200px]')}
                />
                <img
                  src={require(`../assets/images/avatar-${array[2]}.png`).default}
                  className={merge(styles.animateUpDown, 'absolute top-[-40px] right-[-120px]')}
                />
                <img
                  src={require(`../assets/images/avatar-${array[3]}.png`).default}
                  className={merge(styles.animateUpDown, 'absolute top-[180px] right-[-180px]')}
                />
              </>
            )}
            <img src={require('../assets/images/avatar-main.png').default} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Create3dAvatar;
