import { ClickAwayListener, SwipeableDrawer } from '@mui/material';
import { CloseButton, DesignButton } from 'components';
import { Dispatch, SetStateAction } from 'react';
import Carousel from 'react-material-ui-carousel';

interface PopupSideBarProps {
  data: { isOpen: boolean; dataPopup: { name: string; image: string[]; description: string } };
  setIsOpen: Dispatch<
    SetStateAction<{ isOpen: boolean; dataPopup: { name: string; image: string[]; description: string } }>
  >;
}

const PopupSideBar = ({ data, setIsOpen }: PopupSideBarProps) => {
  return (
    <ClickAwayListener
      mouseEvent='onMouseDown'
      touchEvent='onTouchStart'
      onClickAway={() =>
        data.isOpen &&
        setIsOpen({
          ...data,
          isOpen: false,
        })
      }
    >
      <SwipeableDrawer
        variant='persistent'
        onOpen={() =>
          setIsOpen({
            ...data,
            isOpen: true,
          })
        }
        onClose={() =>
          setIsOpen({
            ...data,
            isOpen: false,
          })
        }
        anchor='right'
        open={data.isOpen}
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            border: 'none',
            position: 'absolute',
          },
        }}
      >
        <div style={{ marginTop: 'calc(20vh - 90px)', marginRight: '30px' }} className='items-end w-auto flex flex-col'>
          <CloseButton
            onClick={() =>
              setIsOpen({
                ...data,
                isOpen: false,
              })
            }
            sx={{
              position: 'relative',
              color: 'white',
            }}
          />
          <div
            style={{ background: 'rgba(255, 255, 255, 0.7)', backdropFilter: 'blur(16px)' }}
            className='m-0 flex flex-col items-end rounded-2xl p-4'
          >
            <Carousel
              sx={{
                width: '100%',
                height: 'fit-content',
              }}
            >
              {data.dataPopup.image.map((item, index) => (
                <div
                  key={index}
                  className='w-full h-[300px] xl:h-[300px] sm:h-[200px] flex flex-col p-[5px] rounded-xl justify-center bg-white'
                >
                  <img src={item} className='rounded-xl' width={'100%'} height={'100%'} />
                </div>
              ))}
            </Carousel>
            <div className='w-[15em] sm:w-[15em] xl:w-[25em]'>
              <div className='py-3 font-bold text-2xl'>{data.dataPopup.name}</div>
              <div className='pt-2 font-bold'>Description</div>
              <div className='font pb-[10px]'>{data.dataPopup.description}</div>
              <div className='my-3'>
                <DesignButton size='small' className='w-full'>
                  Buy
                </DesignButton>
              </div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </ClickAwayListener>
  );
};

export default PopupSideBar;
