import { LoadingButton } from '@mui/lab';
import { Divider } from '@mui/material';
import { AppleLogin, FacebookLogin, GoogleLogin } from 'components';
import { APPLE_APP_ID, FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from 'env';
import { useSnackbar } from 'notistack';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { GoogleLoginResponse } from 'react-google-login';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { accountService } from 'services';

type LoginSocialProps = {
  isLinkingAccount?: boolean;
  title: string;
  onTypeChange?: (type: number) => void;
  onDataChange?: (data: GoogleLoginResponse | ReactFacebookLoginInfo | AppleLoginResponse) => void;
  onClose?: () => void;
};

const LoginSocial = ({ isLinkingAccount, title, onTypeChange, onDataChange, onClose }: LoginSocialProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoggedIn, address } = useSelector(profileSelector);

  const { mutate: loginSocial } = useMutation(accountService.linkAccount, {
    onSuccess: () => {
      enqueueSnackbar('Successfully linked to your account');
      onClose?.();
    },
    onSettled: () => {
      if (isLoggedIn) {
        accountService.getProfile({ address }).then((profile) => {
          dispatch(signIn(profile));
        });
      }
    },
  });

  return (
    <>
      <Divider className='text-sm font-bold'>{title}</Divider>

      <div className='flex items-center justify-center gap-2'>
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID!}
          onSuccess={(response) => {
            const data = response as GoogleLoginResponse;
            onTypeChange?.(30);
            onDataChange?.(data);
            if (isLinkingAccount) {
              loginSocial({ token: data.tokenId, linkType: 30 });
            }
          }}
          onFailure={() => onTypeChange!(10)}
          render={({ onClick, disabled }) => (
            <LoadingButton disabled={disabled} loading={false} onClick={onClick}>
              <img src={require('assets/icons/GoogleIcon.png').default} alt='GOOGLE' />
            </LoadingButton>
          )}
        />

        <FacebookLogin
          appId={FACEBOOK_APP_ID!}
          callback={(response) => {
            const data = response as ReactFacebookLoginInfo;
            onTypeChange?.(20);
            onDataChange?.(data);
            if (isLinkingAccount) {
              loginSocial({ token: data.accessToken, linkType: 20 });
            }
          }}
          onFailure={() => onTypeChange?.(10)}
          render={({ onClick, isSdkLoaded, isDisabled }) => (
            <LoadingButton disabled={isDisabled || !isSdkLoaded} loading={false} onClick={onClick}>
              <img src={require('assets/icons/FacebookIcon.png').default} alt='FACEBOOK' />
            </LoadingButton>
          )}
        />
        {false && (
          <AppleLogin
            clientId={APPLE_APP_ID!}
            redirectURI={window.location.href}
            usePopup={true}
            scope='email name'
            responseMode='query'
            callback={(response) => {
              const data = response as AppleLoginResponse;
              if (data.error) onTypeChange?.(10);
              else {
                onTypeChange?.(40);
                onDataChange?.(data);
                if (isLinkingAccount) {
                  loginSocial({ token: data.authorization?.id_token, linkType: 40 });
                }
              }
            }}
            render={({ onClick, disabled }) => (
              <LoadingButton disabled={disabled} onClick={onClick}>
                <img src={require('assets/icons/AppleIcon.png').default} alt='APPLE' />
              </LoadingButton>
            )}
          />
        )}
      </div>
    </>
  );
};

export default LoginSocial;
