import { debounce, Menu, MenuItem } from '@mui/material';
import { useWindowDimensions } from 'hooks';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useQuery } from 'react-query';
import events from 'services/events';
import { merge } from 'utils/common';
import './Calendar.styles.scss';
import styles from './Events.module.scss';
import Popup from './Popup';

const ToolBarCalendar = ({
  label,
  onNavigate,
  eventTypeData,
  setEventTypeData,
  valueInput,
  setValueInput,
  callApiEvent,
  handleSearchEvents,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobile } = useWindowDimensions();
  const isOpenMenu = Boolean(anchorEl);

  const handleChange = (event: any) => {
    setValueInput(event.target.value);
    handleSearchEvents(event.target.value);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const HandleEventClick = (name: String) => {
    const ArrayTemp: any[] = [];
    eventTypeData.map((item: any) => {
      if (item.name === name) {
        item.status = !item.status;
      }
      ArrayTemp.push(item);
      return 1;
    });
    return ArrayTemp;
  };

  return (
    <div className='w-full md:pb-10 pb-4 flex flex-row text-center items-center'>
      <div className='items-center md:w-[317px] w-[195px] md:ml-0 ml-4 h-10 md:h-fit bg-white flex flex-row rounded-lg'>
        <button className='md:p-4 py-2 pl-1' onClick={() => onNavigate('PREV')}>
          <img className='md:w-6 md:h-6 w-4 h-4' src={require('../assets/icons/arrowLeft.svg').default} />
        </button>
        <p className='text-xs md:text-2xl mx-auto'>{label}</p>
        <button className='md:p-4 py-2 pr-1' onClick={() => onNavigate('NEXT')}>
          <img className='md:w-6 md:h-6 w-4 h-4' src={require('../assets/icons/arrowRight.svg').default} />
        </button>
      </div>

      {isMobile ? (
        <div className='ml-auto md:mr-6 mt-1 md:mt-0 mr-4'>
          <button
            aria-haspopup
            id='basic-button'
            aria-controls={isOpenMenu ? 'basic-menu' : undefined}
            aria-expanded={isOpenMenu ? 'true' : undefined}
            onClick={handleClickMenu}
          >
            <img
              src={require('../assets/icons/btn-filter.svg').default}
              className='md:w-12 md:h-12 w-10 h-10'
              alt='filter'
            />
          </button>
          <Menu
            id='basic-menu'
            open={isOpenMenu}
            anchorEl={anchorEl}
            PaperProps={{
              style: {
                filter: 'drop-shadow(0px 12px 16px rgba(143, 209, 236, 0.25))',
                boxShadow: 'drop-shadow(0px 12px 16px rgba(143, 209, 236, 0.25))',
                borderRadius: '16px',
              },
            }}
            className=''
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {eventTypeData.map((item: any, index: any) => (
              <MenuItem
                className='rounded-lg cursor-pointer m-[16px]'
                key={index}
                onClick={() => setEventTypeData(HandleEventClick(item.name))}
              >
                <div
                  className='w-2 h-6 rounded-[100px]'
                  style={{
                    background: `${item.status === true ? item.color : '#808080'}`,
                  }}
                ></div>
                <div className='m-2 text-sm w-full font-normal'>{item.name}</div>
              </MenuItem>
            ))}
          </Menu>
        </div>
      ) : (
        <>
          <div className='flex pl-2 flex-row mr-auto'>
            {eventTypeData.map((item: any, index: any) => (
              <div className='group cursor-pointer' key={index}>
                <div
                  className='bg-white ml-4 lg:w-fit group-hover:w-fit items-center rounded-lg flex flex-row h-12'
                  onClick={() => setEventTypeData(HandleEventClick(item.name))}
                >
                  <div
                    className='w-2 min-w-2 h-8 m-2 rounded-[100px]'
                    style={{
                      background: `${item.status === true ? item.color : '#808080'}`,
                    }}
                  ></div>
                  <div className='m-2 text-sm group-hover:block hidden lg:block w-full font-normal'>{item.name}</div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div
        className={merge(
          styles.filterDropbox,
          `${
            valueInput === '' ? 'w-10 md:w-12' : 'w-[18.75rem] absolute right-0 xl:relative'
          } h-10 md:h-12 xl:w-[18.75rem] max-w-[1444px] hover:w-[18.75rem] focus:w-[18.75rem] hover:mb-0 xl:hover:relative hover:right-0 md:mb-0 mb-[7px] mr-4 hover:absolute flex flex-row bg-white rounded-lg items-center`,
        )}
      >
        <img
          src={require('../assets/icons/glass.svg').default}
          className='md:w-[18px] md:h-[18px] m-3 w-4 h-4 md:m-4'
        />
        <input className='h-full w-full' value={valueInput} onChange={handleChange} autoFocus />
        <img
          src={require('../assets/icons/ic_clear.svg').default}
          className='w-5 h-5 m-4 cursor-pointer'
          hidden={valueInput === '' ? true : false}
          onClick={() => {
            handleSearchEvents('');
            setValueInput('');
          }}
        />
      </div>
    </div>
  );
};

const CalendarComponent = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [eventTypeData, setEventTypeData] = useState<CategoryEventType[]>([]);
  const [eventData, setEventData] = useState<EventType[]>([]);
  const [valuePopup, setValuePopup] = useState({});
  const [valueInput, setValueInput] = useState<string>('');

  const [date, setDate] = useState<{ dateFrom?: Date; dateTo?: Date }>({
    dateFrom: undefined,
    dateTo: undefined,
  });
  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(),
    }),
    [],
  );
  const callApiEvent = (inputValue: string) => {
    events
      .getEvents(
        date.dateFrom
          ? DateTime.fromJSDate(date.dateFrom).startOf('day').valueOf()
          : DateTime.now().toUTC().startOf('day').valueOf(),
        date.dateTo
          ? DateTime.fromJSDate(date.dateTo).endOf('day').valueOf()
          : DateTime.now().toUTC().endOf('day').valueOf(),
        inputValue,
      )
      .then((events) => {
        const list = events.map((e) => {
          return {
            ...e,
            start: new Date(e.start),
            end: new Date(e.end ? e.end : e.start),
          } as any;
        });
        setEventData(list);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchEvents = useCallback(
    debounce((title: string) => {
      callApiEvent(title);
    }, 300),
    [],
  );

  useQuery(['events.getCategoriesEvent'], () =>
    events.getCategoriesEvent().then((categories) => {
      const ArrayTemp: CategoryEventType[] = [];
      categories.map((category) =>
        ArrayTemp.push({
          ...category,
          status: true,
        }),
      );
      setEventTypeData(ArrayTemp);
    }),
  );
  useQuery(['events.getEvents', date.dateFrom, date.dateTo], () => callApiEvent(''));
  const EventWrapper = ({ event, children }: any) => {
    const { title, className } = children.props;
    const customClass = `${className} event-wrapper rbc-event--${event.type}`;
    const hourStart = moment(event.start).hour();
    const hourStop = moment(event.end).hour();
    const gridRowStart = hourStart + 1;
    const statusEvent = eventTypeData.filter((item) => item?.name === event.category?.name)[0]?.status;
    return (
      <div>
        {statusEvent === true && (
          <div
            title={title}
            className={`${customClass} my-1 custom-event-container`}
            onClick={() => {
              setValuePopup(event);
              setIsOpenPopup(true);
            }}
            style={{
              gridRow: `${gridRowStart} / span ${hourStop - hourStart}`,
              background: `${event.category.background}`,
            }}
          >
            <div className='event-dot' style={{ background: `${event.category.color}` }} />
            {children.props.children}
          </div>
        )}
      </div>
    );
  };

  const onRangeChange = useCallback((range: any) => {
    setDate({
      dateFrom: new Date(range.start),
      dateTo: new Date(range.end),
    });
  }, []);

  const localizer = momentLocalizer(moment);
  return (
    <div>
      <Calendar
        components={{
          toolbar: (calendarProps) => (
            <ToolBarCalendar
              {...calendarProps}
              eventTypeData={eventTypeData}
              setEventTypeData={setEventTypeData}
              callApiEvent={callApiEvent}
              valueInput={valueInput}
              setValueInput={setValueInput}
              handleSearchEvents={handleSearchEvents}
            />
          ),
          eventWrapper: EventWrapper,
        }}
        showAllEvents
        className='my-calendar'
        defaultDate={defaultDate}
        events={eventData}
        localizer={localizer}
        view='month'
        views={['month']}
        onView={() => {}}
        onRangeChange={onRangeChange}
        style={{ paddingBottom: '0px' }}
      />
      <Popup isOpenPopup={isOpenPopup} setIsOpenPopup={setIsOpenPopup} dataPopup={valuePopup} />
    </div>
  );
};

export default CalendarComponent;
