import { ConnectWithoutContact } from '@mui/icons-material';
import { Container } from '@mui/material';
import { Fragment } from 'react';
import { merge } from 'utils/common';
import styles from './Home.module.scss';

const runner = ['XIN CHAO', 'HELLO', 'BONJOUR', 'HOLA', 'KONNICHIWA', 'OLÁ', 'ANYOUNG HASEYO', 'NǏN HǍO'];

const Socializing = () => {
  return (
    <>
      <div
        style={{ background: `url(${require('../assets/images/background-social.png').default})` }}
        className='bg-no-repeat bg-center bg-cover md:h-[1200px] h-[800px]'
      >
        <Container className='py-[60px] relative'>
          <Container maxWidth='sm' className='flex flex-col items-start ml-0'>
            <div className='bg-white border border-blue-400 rounded-full md:px-8 px-4 pt-2 mb-6'>
              <div className={merge(styles.header, 'text-left flex items-center gap-2')}>
                <ConnectWithoutContact className='text-[54px] md:block hidden' /> Socializing...
              </div>
            </div>
            <div className='text-base sm:text-center sm:text-xl text-left text-neutral-500'>
              Where you can exchange, meet, and chat with friends around the world. Come and make new friends now!
            </div>
          </Container>

          <div className='relative w-full mt-10'>
            <div
              className={merge(
                styles.animateUpDown,
                'absolute left-[360px] top-[240px] w-[270px] h-[93px] md:flex justify-center items-center hidden',
              )}
              style={{ background: `url(${require('../assets/icons/dialog-1.png').default})` }}
            >
              <div className='text-2xl text-neutral-500 mb-6'>Follow Me</div>
            </div>
            <div
              className={merge(
                styles.animateUpDown,
                'absolute right-[160px] top-[260px] w-[270px] h-[93px] md:flex justify-center items-center hidden',
              )}
              style={{ background: `url(${require('../assets/icons/dialog-1.png').default})` }}
            >
              <div className='text-2xl text-neutral-500 mb-6'>OMG! 😝😝</div>
            </div>
            <div
              className={merge(
                styles.animateUpDown,
                'absolute right-[200px] top-[-120px]  w-[270px] h-[93px] md:flex justify-center items-center hidden',
              )}
              style={{ background: `url(${require('../assets/icons/dialog-1.png').default})` }}
            >
              <div className='text-2xl text-neutral-500 mb-6'>Hey!! 😘</div>
            </div>
            <div className={merge(styles.animateUpDown, 'absolute right-[60px] top-[20px]')}>
              <img src={require('../assets/icons/dialog-2.png').default} />
            </div>
          </div>
        </Container>
      </div>

      <div className={styles.divider}>
        <div className={merge(styles.running, 'flex items-center gap-10 mr-10')}>
          {runner
            .concat(runner)
            .concat(runner)
            .map((text, index) => (
              <Fragment key={index}>
                <span className='text-2xl font-black text-blue-400 whitespace-nowrap'>{text}</span>
                <img src={require('../assets/icons/icon-star-1.png').default} />
              </Fragment>
            ))}
        </div>
      </div>
    </>
  );
};

export default Socializing;
