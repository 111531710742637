import { Favorite, Repeat, Reply } from '@mui/icons-material';
import { Container, Grid } from '@mui/material';
import { DesignButton } from 'components';
import { merge } from 'utils/common';
import styles from './Home.module.scss';

const Campaigns = () => {
  return (
    <div style={{ background: 'linear-gradient(180deg, #F8E7D7 0%, #F4E9FE 100%)' }}>
      <Container maxWidth='lg' className='relative py-[120px]'>
        <Grid container spacing={5}>
          <Grid item md={6} xs={12} className='flex flex-col items-start justify-center'>
            <div className={styles.header}>FOR PARTNERSHIP</div>
            <div className='text-xl text-justify text-neutral-500'>
              My Meta Farm is a 3D socializing platform & a web3 Metaverse where players are free to be creative and
              enjoy exciting activities in an open world. My Meta Farm provides many tools that empower users to
              manifest ideas, unleash creativity and make money from own ideas
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <img src={require('../assets/images/campaign-0.png').default} className='mx-auto' />
          </Grid>
        </Grid>

        <img
          src={require('../assets/icons/icon-star-2.png').default}
          className='absolute left-[20%] top-[60px] md:block hidden animate-pulse'
        />
        <img
          src={require('../assets/icons/icon-star-2.png').default}
          className='absolute right-[-180px] bottom-[320px] md:block hidden animate-pulse'
        />

        <img
          src={require('../assets/icons/animate-rocket-1.png').default}
          className='absolute bottom-[60px] left-[-320px] hidden md:block'
          data-aos='fade-up-right'
          data-aos-duration='800'
        />
        <img
          src={require('../assets/icons/animate-rocket-2.png').default}
          className='absolute top-[20px] right-[-320px] hidden md:block'
          data-aos='fade-up-left'
          data-aos-duration='800'
        />
      </Container>

      <Container className='relative py-[90px]'>
        <div className={styles.header}>AMAZING CAMPAIGNS</div>
        <Container maxWidth='md'>
          <div className='text-xl text-neutral-500 text-center'>
            My Meta Farm is proud to cooperate with partners like Idle StoneAge, QuesFi, etc to bring many interesting
            activities to the communities. We always have good faith & work closely with our partners. That has brought
            great success with the number of participants exceeding expectations
          </div>
        </Container>

        <Grid container spacing={8} className='justify-center mt-[40px]'>
          {[
            {
              image: require('../assets/images/campaign-1.png').default,
              like: '9.6K+',
              reply: '4K+',
              retweet: '28.5K+',
              user: '28.782',
            },
            {
              image: require('../assets/images/campaign-2.png').default,
              like: '15K+',
              reply: '6K+',
              retweet: '25K+',
              user: '25.892',
            },
            {
              image: require('../assets/images/campaign-3.png').default,
              like: '19.8K+',
              reply: '8.7K+',
              retweet: '21.7K+',
              user: '21.758',
            },
          ].map((item, index) => (
            <Grid item key={index} lg={4} xs={12}>
              <img src={item.image} className='mx-auto mb-[24px]' />
              <div className='bg-white rounded-[12px] pr-[16px] py-[24px]'>
                <Grid container>
                  <Grid item xs={4} className='flex items-center justify-center gap-2'>
                    <Favorite style={{ color: '#F9595F', fontSize: 32 }} />
                    <div>
                      <div className='font-bold'>{item.like}</div>
                      <div>Likes</div>
                    </div>
                  </Grid>
                  <Grid item xs className='flex items-center justify-center gap-2 border-l border-r border-neutral-300'>
                    <Reply style={{ color: '#885CFF', fontSize: 40 }} />
                    <div>
                      <div className='font-bold'>{item.reply}</div>
                      <div>Reply</div>
                    </div>
                  </Grid>
                  <Grid item xs={4} className='flex items-center justify-center gap-2'>
                    <Repeat style={{ color: '#179CF3', fontSize: 32, transform: 'rotate 90)' }} />
                    <div>
                      <div className='font-bold'>{item.retweet}</div>
                      <div>Retweet</div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='flex justify-center gap-2 text-2xl mt-[16px]'>
                      <span className='font-bold'>{item.user}</span>
                      Participants
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ))}
        </Grid>

        <img
          src={require('../assets/icons/animate-cloud.png').default}
          className={merge(styles.animateLeftRight, 'absolute left-[0px] top-[180px] hidden md:block')}
        />
        <img
          src={require('../assets/icons/animate-cloud.png').default}
          className={merge(styles.animateLeftRight, 'absolute right-[0px] top-[0px] hidden md:block')}
        />
      </Container>

      <Container className='relative py-[90px]'>
        <div className={merge(styles.header, 'text-blue-200 text-[40px]')}>LET'S BUILD AN</div>
        <div className={styles.header}>AWESOME COLLABORATION</div>

        <Grid container spacing={8} className='justify-center mt-[40px]'>
          {[
            {
              image: require('../assets/images/event-1.png').default,
              header: 'Avatars',
              description: `The 3D avatar is the representation of your inner self in my Meta Farm Metaverse. Our tools let you express the personality you love.`,
              url: 'https://bit.ly/3OF1axa',
              explore: 'CREATE SPECIES',
              size: 5,
            },
            {
              image: require('../assets/images/event-2.png').default,
              header: 'Digital Fashion',
              description: `Digital Fashion is an outfit designed in a professional 3D-applications, which is applied to a metaverse avatar.  Design a fashion line for any avatar species including your own`,
              url: 'https://bit.ly/3vkVNN4',
              explore: 'DESIGN FASHION',
              size: 5,
            },
            {
              image: require('../assets/images/event-3.png').default,
              header: 'Event Online',
              description: `My Meta Farm will create real-life events right in the metaverse. We can actively and flexibly organize activities and events for our communities.`,
              url: 'https://bit.ly/3JqtaE1',
              explore: 'CREATE EVENT',
              size: 4,
            },
            {
              image: require('../assets/images/event-4.png').default,
              header: 'Game Studio',
              description: `Bring great games to players to play & enjoy. game developers have the opportunity to demonstrate great game design skills and bring their own brand to the world.`,
              url: 'https://bit.ly/3JiFh5N',
              explore: 'CREATE MINIGAME',
              size: 4,
            },
            {
              image: require('../assets/images/event-5.png').default,
              header: 'Cross Marketing',
              description: `Brands will appear on TVC, Banner on Road, Square, Building in our metaverse. Easily communicate and increase brand awareness in the city of My Meta Farm.`,
              url: 'https://bit.ly/3zAmkID',
              explore: 'COLLABORATE',
              size: 4,
            },
          ].map((item, index) => (
            <Grid item key={index} md={item.size} xs={12} className='flex flex-col items-center'>
              <img src={item.image} className='mb-[24px]' />
              <div className='font-bold text-2xl mb-1'>{item.header}</div>
              <div className='text-xl text-center'>{item.description}</div>
              <div className='flex-1 flex items-end mt-[20px]'>
                <a href={item.url} target='_blank' rel='noreferrer'>
                  <DesignButton color='info'>{item.explore}</DesignButton>
                </a>
              </div>
            </Grid>
          ))}
        </Grid>

        <img
          src={require('../assets/icons/animate-cloud.png').default}
          className={merge(styles.animateLeftRight, 'absolute left-[0px] top-[0px] hidden md:block')}
        />
        <img
          src={require('../assets/icons/animate-cloud.png').default}
          className={merge(styles.animateLeftRight, 'absolute right-[0px] top-[180px] hidden md:block')}
        />
      </Container>
    </div>
  );
};

export default Campaigns;
