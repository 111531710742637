import { Button } from '@mui/material';
import { useValidNetwork } from 'hooks';
import { walletService } from 'services';

const TabConnectWallet = () => {
  const { validNetwork } = useValidNetwork();

  return (
    <div className='flex flex-col gap-[24px]'>
      <div>You have to connect MetaMask Wallet first before linking your game account.</div>

      <Button
        disableElevation
        color='inherit'
        onClick={() => validNetwork(walletService.connectWallet)}
        startIcon={<img src={require('assets/icons/MetaMask.png').default} />}
      >
        CONNECT METAMASK
      </Button>

      <div className='flex justify-center'>
        <a
          href='https://news.mymetafarm.com/how-to-add-polygon-to-metamask/'
          className='underline'
          rel='noreferrer'
          target='_blank'
        >
          How to use Metamask?
        </a>
      </div>
    </div>
  );
};

export default TabConnectWallet;
