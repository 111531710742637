import { Place } from '@mui/icons-material';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Polygon, Popup } from 'react-leaflet';

interface PolygonContainerProps {
  id: string;
  position: [number, number][];
  color?: string;
  fillOpacity?: number;
  data: { name: string; image: string[]; description: string };
  setIsOpen: Dispatch<
    SetStateAction<{ isOpen: boolean; dataPopup: { name: string; image: string[]; description: string } }>
  >;
}
let prevLayerClicked: any = null;

const PolygonContainer = ({ id, data, position, color, fillOpacity, setIsOpen }: PolygonContainerProps) => {
  const [location, setLocation] = useState('');
  const eventHandlers = useMemo(
    () => ({
      click(e: any) {
        if (prevLayerClicked !== null) {
          prevLayerClicked.setStyle({
            opacity: 0,
          });
        }
        setIsOpen({
          isOpen: true,
          dataPopup: data,
        });
        const layer = e.target;
        layer.setStyle({
          opacity: 1,
        });

        prevLayerClicked = layer;

        setLocation(
          `${Math.round(e.target.getBounds().getCenter().lng)}, ${Math.round(e.target.getBounds().getCenter().lat)}`,
        );
      },
    }),
    [data, setIsOpen],
  );
  return (
    <Polygon
      eventHandlers={eventHandlers}
      color='#ffde48'
      key={id}
      positions={position}
      fillColor={color !== undefined ? color : 'transparent'}
      fillOpacity={fillOpacity !== undefined ? fillOpacity : 0}
      opacity={0}
    >
      <Popup className=''>
        {data.name}
        <div className='font-bold text-xs'>
          <Place />
          {location}
        </div>
      </Popup>
    </Polygon>
  );
};

export default PolygonContainer;
