import { Avatar, Button, Chip, Container, Dialog, IconButton, TextField, Typography } from '@mui/material';
import { DesignButton } from 'components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { copy, shorten } from 'utils/common';
import { RegisterPopup } from 'views/PreRegister';
import { ProfileRefferral } from './components';
import { ArrowBack } from '@mui/icons-material';
import { publicRoute } from 'routes';
import { Link } from 'react-router-dom';
import { ReferralCodePopup, ResetPasswordPopup } from 'views/Profile/popups';

const Profile = () => {
  const { address, email, emailVerified, parentUser } = useSelector(profileSelector);

  const [openRegister, setOpenRegister] = useState(false);
  const [isOpenRefferal, setOpenRefferal] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);

  return (
    <Container className='py-10 lg:py-20'>
      <div>
        <Link to={publicRoute.home.path}>
          <Button variant='text' color='inherit' startIcon={<ArrowBack />}>
            Back
          </Button>
        </Link>
      </div>
      <div className='flex flex-col sm:flex-row justify-center'>
        <Avatar
          src={require('assets/images/profile-avatar.png').default}
          className='md:w-[120px] md:h-[120px] w-[100px] h-[100px] mr-12 bg-red-100 border-2'
        />

        <div className='flex flex-col items-start gap-[32px]'>
          <div>
            <div className='font-bold text-2xl pt-[15px] sm:pt-[0px]'>Profile</div>
            <div>You can use your Wallet Address or your Email to login into game</div>
          </div>

          <div>
            <div>Wallet Address</div>
            <div className='flex items-center'>
              <div className='font-bold text-2xl'>{shorten(address)}</div>
              <IconButton onClick={() => copy(address)} title='Copy'>
                <img src={require('assets/icons/copy.svg').default} />
              </IconButton>
            </div>
          </div>

          {email ? (
            <>
              <div>
                <div>Email</div>
                <div className='font-bold text-2xl'>
                  {email} {emailVerified ? <Chip color='success' label='Verified' /> : <Chip label='Unverified' />}
                </div>
                {!emailVerified && (
                  <Button
                    className='mt-3'
                    onClick={() => {
                      setOpenRegister(true);
                    }}
                  >
                    Verify Email
                  </Button>
                )}
              </div>
              <div className='w-full'>
                <div>Password</div>
                <div className='flex justify-between'>
                  <div className='font-bold'>****************</div>
                  <Typography
                    variant='body2'
                    className='underline font-bold text-info-dark cursor-pointer'
                    onClick={() => setOpenResetPassword(true)}
                  >
                    Reset Password
                  </Typography>
                </div>
              </div>
            </>
          ) : (
            <DesignButton onClick={() => setOpenRegister(true)}>LINK ACCOUNT</DesignButton>
          )}

          {parentUser?.refCode ? (
            <TextField
              InputProps={{ readOnly: true }}
              label='Parent User Code'
              value={parentUser?.refCode}
              disabled={!!parentUser}
            />
          ) : (
            <Typography
              variant='body1'
              className='underline text-info-dark cursor-pointer'
              onClick={() => setOpenRefferal(true)}
            >
              Get Referral Code
            </Typography>
          )}

          <ProfileRefferral />
        </div>
      </div>

      <Dialog fullWidth maxWidth='xs' open={openRegister} classes={{ container: 'items-start', paper: 'top-[60px]' }}>
        <RegisterPopup onClose={() => setOpenRegister(false)} />
      </Dialog>

      <div className='xl:block' style={{ display: 'none' }}>
        <img
          src={require('assets/images/metaverse-icon-2.png').default}
          className='absolute top-[210px] right-[84px]'
          style={{ right: -60 }}
          data-aos='fade-up-left'
          data-aos-duration='800'
        />
      </div>

      <Dialog open={isOpenRefferal}>
        <ReferralCodePopup onClose={() => setOpenRefferal(false)} />
      </Dialog>

      <Dialog fullWidth maxWidth='sm' open={openResetPassword}>
        <ResetPasswordPopup email={email!} onClose={() => setOpenResetPassword(false)} />
      </Dialog>
    </Container>
  );
};

export default Profile;
