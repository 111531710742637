import { Container, Tooltip } from '@mui/material';

const downloadSystems = [
  {
    name: 'WINDOWS',
    url: 'https://tinyurl.com/mrymknsz',
    image: require('../assets/icons/windows-icon.png').default,
    disabled: false,
  },
  {
    name: 'MACOS',
    url: 'https://tinyurl.com/3er6mfkf',
    image: require('../assets/icons/macos-icon.png').default,
    disabled: false,
  },
  { name: 'IOS', url: 'https://tinyurl.com/5f8p29xn', image: require('../assets/icons/ios-icon.png').default },
  { name: 'ANDROID', url: 'https://tinyurl.com/yc2ptfxn', image: require('../assets/icons/android-icon.png').default },
];

const TopBanner = () => {
  return (
    <Container disableGutters className='max-w-full flex flex-col items-center'>
      <div className='relative w-full'>
        <video
          src={require('../assets/videos/banner-characters.mp4').default}
          {...{ autoPlay: true, muted: true, loop: true }}
          className='w-full'
        />
        <div className='absolute flex justify-center w-full bottom-12 sm:bottom-20 lg:bottom-28 xl:bottom-40 2xl:bottom-96 z-10'>
          <img
            src={require('../assets/images/background-text.png').default}
            alt='background-text'
            className='w-[240px] sm:w-[360px] md:w-[480px] lg:w-[640px] xl:w-[760px] 2xl:w-auto'
          />
        </div>
        <div className='bg-[#5F2AF5] fixed w-full top-[80px] py-1 sm:py-2 xl:py-4 justify-center items-center space-x-4 flex z-50'>
          <div className='text-[10px] sm:text-xl font-extrabold text-white'>DOWNLOAD NOW!</div>
          {downloadSystems.map((item, index) => {
            return !item.disabled ? (
              <a
                href={item.url}
                className='hover:bg-white hover:rounded-md'
                target='_blank'
                rel='noreferrer'
                key={item.name}
              >
                <img src={item.image} alt={item.name} className='w-[16px] sm:w-auto' />
              </a>
            ) : (
              <Tooltip title='Coming soon' placement='top' key={item.name}>
                <img src={item.image} alt={item.name} className='w-[16px] sm:w-auto' />
              </Tooltip>
            );
          })}
          <a
            href='https://news.mymetafarm.com/guide-to-download-my-meta-farm-to-your-computer'
            target='_blank'
            rel='noreferrer'
            className='underline text-white text-[10px] sm:text-sm font-semibold'
          >
            How to Download?
          </a>
        </div>
      </div>

      <div id='next-section' />
    </Container>
  );
};

export default TopBanner;
