import { client } from './axios';

const getEvents = (fromDate?: any, toDate?: any, title?: string, category?: string): Promise<EventTypeResponse[]> => {
  return client.get(
    `/market-apis/api/calendarevent/getAll?fromDate=${fromDate}&toDate=${toDate}&title=${title || ''}&category=${
      category || ''
    }`,
  );
};
const getCategoriesEvent = (): Promise<CategoryEventType[]> => client.get(`/market-apis/api/calendarcategory`);

export default {
  getEvents,
  getCategoriesEvent,
};
