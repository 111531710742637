import { useNotification } from 'hooks';
import { default as jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { signIn, signOut } from 'reducers/profileSlice';
import { saveSystem } from 'reducers/systemSlice';
import { systemService, walletService } from 'services';
import { Maintenance } from 'views/Home';

const Controller = ({ children }: any) => {
  useNotification();
  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isMaintain, setIsMaintain] = useState(false);

  useEffect(() => {
    try {
      const profile = JSON.parse(localStorage.getItem('profile')!);
      const { exp } = jwtDecode(profile.accessToken) as any;
      if (Date.now() / 1000 < exp - 600) {
        dispatch(signIn(profile));
        if (!profile.isGlip) {
          walletService.connectProvider();
        }
      }
    } catch {
      dispatch(signOut({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useQuery(['fetchPayments'], () => systemService.fetchPayments(), { staleTime: Infinity });

  useQuery(['fetchConfig'], () => systemService.fetchConfig(), {
    onSuccess: (data) => {
      setIsMaintain(data.isMaintainceEnabled);
      dispatch(saveSystem(data));
    },
    onError: () => {
      setIsMaintain(true);
    },
    onSettled: () => {
      setIsSuccess(true);
    },
  });

  return isMaintain ? <Maintenance /> : isSuccess ? children : <></>;
};

export default Controller;
