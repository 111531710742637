import { Edit } from '@mui/icons-material';
import { Button, FormControl, InputAdornment, TextField } from '@mui/material';
import { DesignButton } from 'components';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { accountService } from 'services';
import { TabVerifySuccess } from '.';

type TabVerifyProps = {
  email: string;
  onBack: () => void;
};

const TabVerify = ({ email, onBack }: TabVerifyProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoggedIn, address } = useSelector(profileSelector);

  const { control, handleSubmit } = useForm();

  const [isSuccess, setIsSuccess] = useState(false);

  const { mutate, isLoading } = useMutation(accountService.verifyAccount, {
    onSuccess: () => {
      setIsSuccess(true);
    },
    onSettled: () => {
      if (isLoggedIn) {
        accountService.getProfile({ address }).then((profile) => {
          dispatch(signIn(profile));
        });
      }
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutate(values as AccountVerifyType);
    })();
  };

  const handleClickResend = () => {
    accountService.resendCode({ email }).then(() => {
      enqueueSnackbar('Activation code has been sent');
    });
  };

  return isSuccess ? (
    <TabVerifySuccess />
  ) : (
    <div className='flex flex-col gap-[24px]'>
      <Controller
        name='email'
        defaultValue={email}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <label>Email</label>
            <TextField
              {...field}
              type='email'
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position='end' className='-mr-2'>
                    <Button
                      disableElevation
                      variant='outlined'
                      color='info'
                      size='small'
                      startIcon={<Edit />}
                      onClick={onBack}
                    >
                      Edit
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
      />

      <Controller
        name='code'
        defaultValue=''
        control={control}
        rules={{ required: 'Activation Code is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Activation Code</label>
            <TextField {...field} error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />

      <div className='flex justify-center'>
        <DesignButton className='px-[48px]' loading={isLoading} onClick={handleClickSubmit}>
          ACTIVE
        </DesignButton>
      </div>

      <div className='flex justify-center text-[14px]'>
        Do not receive code?
        <span onClick={handleClickResend} className='underline cursor-pointer ml-2'>
          Resend code
        </span>
      </div>
    </div>
  );
};

export default TabVerify;
