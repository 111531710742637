import { AlignVerticalCenter, CategoryOutlined, Remove, RestartAlt } from '@mui/icons-material';
import { Button, debounce, FormControlLabel, Menu, MenuItem, Popover, Switch, TextField } from '@mui/material';
import { InputNumber } from 'components';
import { useAnchor } from 'hooks';
import { parse } from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { systemService } from 'services';

const SORT_TYPES = [
  { orderBy: 'price', desc: 'false', name: 'Price: Low To High' },
  { orderBy: 'price', desc: 'true', name: 'Price: High To Low' },
];

type MarketFilterType = {
  onSearchChange: (search?: any) => void;
};

const MarketFilter = ({ onSearchChange }: MarketFilterType) => {
  const location = useLocation();
  const { tab, page = 1, ...query } = parse(location.search, { ignoreQueryPrefix: true });

  const { address } = useSelector(profileSelector);
  const { data: payments } = useQuery(['fetchPayments'], () => systemService.fetchPayments());

  const [orderBy, setOrderBy] = useState(query.orderBy || SORT_TYPES[0].orderBy);
  const [desc, setDesc] = useState(query.desc || SORT_TYPES[0].desc);
  const [owned, setOwned] = useState(query.owned || '');
  const [symbol, setSymbol] = useState(query.symbol || payments?.[0]?.symbol);
  const [minPrice, setMinPrice] = useState(query.minPrice || '');
  const [maxPrice, setMaxPrice] = useState(query.maxPrice || '');
  const [search, setSearch] = useState(query.search || '');
  const [params, setParams] = useState({ symbol, minPrice, maxPrice, search, page });

  const [anchorSort, openSort, onOpenSort, onCloseSort] = useAnchor();
  const [anchorPrice, openPrice, onOpenPrice, onClosePrice] = useAnchor();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeParams = useCallback(
    debounce((values) => {
      setParams((prev) => ({ ...prev, ...values }));
    }, 300),
    [],
  );

  const handleClickReset = () => {
    setOwned('');
    setSymbol(payments?.[0]?.symbol);
    setMinPrice('');
    setMaxPrice('');
    setSearch('');
    setParams({ symbol: payments?.[0]?.symbol, minPrice: '', maxPrice: '', search: '', page: '1' });
  };

  useEffect(() => {
    onSearchChange({ orderBy, desc, owner: owned === 'true' ? address : '', ...params });
  }, [onSearchChange, orderBy, desc, owned, address, params]);

  return (
    <div className='flex-1 flex lg:flex-row flex-col items-start justify-between gap-5'>
      <div className='flex lg:flex-row flex-col items-start gap-5'>
        <Button
          variant='text'
          color='inherit'
          classes={{ textInherit: 'bg-white hover:brightness-90 px-4' }}
          startIcon={<CategoryOutlined />}
          onClick={onOpenSort}
        >
          {SORT_TYPES.find((item) => item.orderBy === orderBy && item.desc === desc)?.name ?? SORT_TYPES[0].name}
        </Button>
        <Menu
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          anchorEl={anchorSort}
          open={openSort}
          onClose={onCloseSort}
          onClick={onCloseSort}
        >
          {SORT_TYPES.map((item, index) => (
            <MenuItem
              key={index}
              classes={{ selected: 'bg-info-light' }}
              selected={item.orderBy === orderBy && item.desc === desc}
              onClick={() => {
                setOrderBy(item.orderBy);
                setDesc(item.desc);
              }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Menu>

        <Button
          variant='text'
          color='inherit'
          classes={{ textInherit: 'bg-white hover:brightness-90 px-4' }}
          startIcon={<AlignVerticalCenter />}
          onClick={onOpenPrice}
        >
          Price Range
        </Button>
        <Popover
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={openPrice}
          anchorEl={anchorPrice}
          onClose={() => {
            onClosePrice();
            setTimeout(() => {
              setSymbol(params.symbol);
              setMinPrice(params.minPrice);
              setMaxPrice(params.maxPrice);
            }, 0);
          }}
        >
          <div className='flex flex-col gap-3 p-3'>
            <TextField select fullWidth value={symbol} onChange={(event) => setSymbol(event.target.value)}>
              {payments?.map((item) => (
                <MenuItem key={item.id} value={item.symbol}>
                  {item.symbol}
                </MenuItem>
              ))}
            </TextField>
            <div className='flex items-center gap-1'>
              <TextField
                placeholder='Min'
                InputProps={{
                  inputComponent: InputNumber,
                  style: { width: 120 },
                }}
                value={minPrice}
                onChange={(event) => setMinPrice(event.target.value ?? '')}
              />
              <Remove color='action' />
              <TextField
                placeholder='Max'
                InputProps={{
                  inputComponent: InputNumber,
                  style: { width: 120 },
                }}
                value={maxPrice}
                onChange={(event) => setMaxPrice(event.target.value ?? '')}
              />
            </div>
            <Button
              color='info'
              onClick={() => {
                setParams((prev) => ({ ...prev, symbol, minPrice, maxPrice }));
                onClosePrice();
              }}
            >
              Apply
            </Button>
          </div>
        </Popover>

        <TextField
          placeholder='Search...'
          InputProps={{ className: 'bg-white text-black' }}
          value={search}
          onChange={(event) => {
            const { value } = event.target;
            setSearch(value);
            debounceChangeParams({ search: value });
          }}
        />

        <FormControlLabel
          label='My Listing'
          control={
            <Switch
              color='info'
              checked={owned === 'true'}
              onChange={(event, checked) => setOwned(checked ? 'true' : 'false')}
            />
          }
        />
      </div>

      <Button
        variant='text'
        color='inherit'
        classes={{ textInherit: 'bg-white hover:brightness-90 px-4' }}
        startIcon={<RestartAlt />}
        onClick={handleClickReset}
      >
        Reset
      </Button>
    </div>
  );
};

export default MarketFilter;
