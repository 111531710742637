import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export type ProfileState = {
  isLoggedIn?: boolean;
  isGlip?: boolean;
  accessToken?: string;
  address?: string;
  email?: string;
  emailVerified?: boolean;
  username?: string;
  refCode?: string;
  parentUser?: {
    username: string;
    refCode: string;
    address: string;
  };
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {} as ProfileState,
  reducers: {
    signIn: (state, { payload }) => {
      const profile = { ...state, ...payload, isLoggedIn: true };
      localStorage.setItem('profile', JSON.stringify(profile));
      return profile;
    },
    signOut: (state, { payload }) => {
      localStorage.removeItem('profile');
      localStorage.removeItem('pageTracking');
      return { isLoggedIn: false };
    },
  },
});

export const { signIn, signOut } = profileSlice.actions;

export const profileSelector = ({ profile }: RootState) => profile;

export default profileSlice.reducer;
