import { Container, Grid } from '@mui/material';
import { merge } from 'utils/common';
import styles from './Home.module.scss';

const CreativeTools = () => {
  return (
    <div
      style={{ background: `url(${require('../assets/images/background-creative.png').default})` }}
      className='bg-no-repeat bg-center bg-cover'
    >
      <Container className='py-[60px]'>
        <Grid container spacing={6} className='justify-center'>
          <Grid item xs={12} lg={6}>
            <div className='max-w-[600px] mx-auto xl:ml-auto xl:mr-0'>
              <div className={merge(styles.header, 'text-left')}>Creative Tools</div>
              <div className='text-base sm:text-xl text-neutral-500'>
                Create new houses, beautiful lands, unique outfits, challenges and more. With this tool, nothing can
                stop your imagination.
              </div>
            </div>
          </Grid>

          <Grid item xs={12} lg={6}>
            <ul className='flex flex-col max-w-[600px] mx-auto xl:mx-0 gap-4 md:py-5'>
              {['No code required', 'Build & Share', 'Create for free'].map((text, index) => (
                <li key={index} className='text-base md:text-2xl text-[#18446E] font-bold'>
                  ✦ {text}
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>

        <Grid container spacing={6} className='mt-2 justify-center'>
          {[
            { image: require('../assets/videos/creative-1.gif').default },
            { image: require('../assets/videos/creative-2.gif').default },
          ].map((item, index) => (
            <Grid item key={index}>
              <img src={item.image} className='border-[4px] border-white rounded-[12px]' />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default CreativeTools;
