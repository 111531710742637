import { useWindowDimensions } from 'hooks';
import {
  Create3dAvatar,
  CreativeTools,
  DigitalFashion,
  Map,
  MarketplaceBanner,
  MetaverseWorld,
  Socializing,
  TopBanner,
  WhatAreTheDifferences,
  Partners,
  Team,
} from './components';

const Home = () => {
  const { isMobile } = useWindowDimensions();
  return (
    <>
      <TopBanner />
      {!isMobile && <Map />}
      <WhatAreTheDifferences />
      <MetaverseWorld />
      <Create3dAvatar />
      <DigitalFashion />
      <Socializing />
      <CreativeTools />
      <MarketplaceBanner />
      <div style={{ background: 'linear-gradient(180deg, #f3e8fe 0%, #c9cbff 26.57%, #e3faff 48.25%, #fff3e3 100%)' }}>
        <Partners />
        <Team />
      </div>
    </>
  );
};

export default Home;
