import { web3 } from 'contracts';

export const formatNumber = (number) => Number(number ?? 0).toLocaleString();

export const shorten = (address, head = 6, tail = 6) => {
  if (!web3.utils.isAddress(address)) return address;
  return address.slice(0, head) + '...' + address.slice(address.length - tail);
};

export const copy = (text) => {
  navigator.clipboard.writeText(text);
};

export const getBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
};

export const randomTokenID = () => {
  const mils = Date.now() % (1e9 + 7);
  const rand = Math.floor(Math.random() * 9e3) + 1e3;
  return `${mils}${rand}`;
};

export const getPolygonFee = (chainId) => {
  if (chainId === 80001)
    return fetch('https://gasstation-mumbai.matic.today/v2')
      .then((response) => response.json())
      .then((data) => data.fast.maxFee * 1e9);
  if (chainId === 137)
    return fetch('https://gasstation-mainnet.matic.network/v2')
      .then((response) => response.json())
      .then((data) => data.fast.maxFee * 1e9);
};

export const generateQueryString = (q) => {
  let queryString = '';
  if (q) {
    const queryKeys = Object.keys(q);
    queryKeys.forEach((key) => {
      if (q[key]) {
        if (q[key].toString().length) {
          queryString += `${key}=${q[key]}&`;
        }
      }
    });
    if (queryKeys.length > 0 && queryString[queryString.length - 1] === '&') {
      queryString = queryString.slice(0, -1);
    }
  }
  return queryString;
};

export const getCookie = (cookieName) => {
  var cookieString = RegExp(cookieName + '=[^;]+').exec(document.cookie);
  return decodeURIComponent(!!cookieString ? cookieString.toString().replace(/^[^=]+./, '') : '');
};

export { default as merge } from './merge';
