import { QrCodeScanner } from '@mui/icons-material';
import { Dialog, Grid, IconButton, Tooltip } from '@mui/material';
import { stringify } from 'qs';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { accountService } from 'services';
import { copy } from 'utils/common';
import { PopupQrCode } from '.';

const shorten = (link: string, head = 12, tail = 7) => {
  return link.slice(0, head) + '...' + link.slice(link.length - tail);
};

const ProfileRefferral = () => {
  const { refCode } = useSelector(profileSelector);

  const { data: accountsInvited } = useQuery(['accountService.getInvited'], () => accountService.getInvited());

  const [isOpenCode, setOpenCode] = useState(false);
  const url = window.location.origin + stringify({ tab: 'account', refCode }, { addQueryPrefix: true });

  if (!refCode) return null;

  return (
    <div className='bg-black/5 rounded-[12px] p-[24px] relative w-full'>
      <img src={require('assets/images/speaker-icon.png').default} className='absolute bottom-0 right-0' />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <div className='text-action-dark'>Your Referral Code</div>
          <div className='flex items-center'>
            <div className='font-bold text-2xl'>{refCode}</div>
            <IconButton onClick={() => copy(refCode)} title='Copy'>
              <img src={require('assets/icons/copy.svg').default} />
            </IconButton>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className='text-action-dark'>Your Referral Link</div>
          <div className='flex items-center'>
            <div className='font-bold text-2xl'>{shorten(url)}</div>
            <IconButton onClick={() => copy(url)} title='Copy'>
              <img src={require('assets/icons/copy.svg').default} />
            </IconButton>
          </div>
        </Grid>

        <Grid item xs={4} md={2}>
          <IconButton className='rounded bg-white mt-2 text-black/70' onClick={() => setOpenCode(true)}>
            <QrCodeScanner fontSize='large' />
          </IconButton>
        </Grid>

        <Grid item xs={8} md={4}>
          <div className='flex items-center'>
            <div className='text-action-dark'>Total Referrals</div>
            <Tooltip
              title='Successful Referral refers to the number of referrals who completed register process'
              placement='top'
            >
              <IconButton onClick={() => copy(refCode)}>
                <img src={require('assets/images/question-mark-icon.svg').default} />
              </IconButton>
            </Tooltip>
          </div>
          <div className='font-bold text-2xl'>{accountsInvited ? accountsInvited.length : 0}</div>
        </Grid>
      </Grid>

      <Dialog open={isOpenCode}>
        <PopupQrCode text={url} onClose={() => setOpenCode(false)} />
      </Dialog>
    </div>
  );
};

export default ProfileRefferral;
