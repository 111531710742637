import { CloseOutlined, MenuOutlined } from '@mui/icons-material';
import { AppBar, Container, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { DesignButton, NetworkBar, RegisterButton } from 'components';
import { AppMenu } from 'containers';
import { useAnchor, useValidNetwork } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector, signOut } from 'reducers/profileSlice';
import { publicRoute } from 'routes';
import { glipService, walletService } from 'services';
import { shorten } from 'utils/common';

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { validNetwork } = useValidNetwork();
  const { isLoggedIn, address } = useSelector(profileSelector);

  const [showMenu, setShowMenu] = useState(false);
  const [anchorProfile, openProfile, onOpenProfile, onCloseProfile] = useAnchor();

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  return (
    <AppBar position='sticky' elevation={0} style={{ background: '#F8F8F8' }}>
      <Toolbar className='h-[80px]'>
        <Container className='flex items-center px-0'>
          <Link to={publicRoute.home.path}>
            <img src={require('assets/icons/MyMetaFarm.png').default} className='h-[72px]' />
          </Link>
          <NetworkBar />

          <div className='flex-1' />
          <div className='mobile-nav' style={{ display: showMenu ? 'flex' : 'none' }}>
            <AppMenu />
          </div>

          <div className='flex-1' />
          <div className='flex items-center gap-3'>
            {isLoggedIn ? (
              <>
                <DesignButton onClick={onOpenProfile}>{shorten(address)}</DesignButton>
                <Menu anchorEl={anchorProfile} open={openProfile} onClose={onCloseProfile} onClick={onCloseProfile}>
                  <Link to={publicRoute.inventory.path}>
                    <MenuItem>Inventory</MenuItem>
                  </Link>
                  <Link to={publicRoute.profile.path}>
                    <MenuItem>Profile</MenuItem>
                  </Link>
                  <MenuItem
                    onClick={() => {
                      dispatch(signOut({}));
                    }}
                  >
                    Disconnect
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <RegisterButton />
                <DesignButton className='whitespace-nowrap' onClick={() => validNetwork(walletService.connectWallet)}>
                  CONNECT WALLET
                </DesignButton>
                <DesignButton
                  className='whitespace-nowrap'
                  color='secondary'
                  onClick={() => glipService.connectWallet()}
                >
                  GLIP WALLET
                </DesignButton>
              </>
            )}
          </div>

          <IconButton color='secondary' className='hamburger-icon ml-3' onClick={() => setShowMenu((prev) => !prev)}>
            {showMenu ? <CloseOutlined /> : <MenuOutlined />}
          </IconButton>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
