import AOS from 'aos';
import { AppTheme } from 'containers';
import { GA_MEASUREMENT_ID, GOOGLE_CLIENT_ID } from 'env';
import { gapi } from 'gapi-script';
import { PublicLayout } from 'layouts';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from 'reducers/store';
import { queryClient } from 'services';

AOS.init();

ReactGA.initialize(GA_MEASUREMENT_ID!);

const App = () => {
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({ clientId: GOOGLE_CLIENT_ID, scope: '' });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider preventDuplicate variant='success' anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <AppTheme>
            <BrowserRouter>
              <Routes>
                <Route path='/*' element={<PublicLayout />} />
              </Routes>
            </BrowserRouter>
          </AppTheme>
        </SnackbarProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
