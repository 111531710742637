import { ImageOverlay } from 'react-leaflet';

interface ImageOverlayProps {
  bounds: [number, number][];
  image: string;
  type: string;
}
const ImageOverlayContainer = ({ bounds, image, type }: ImageOverlayProps) => {
  return <ImageOverlay url={image} zIndex={30} bounds={bounds} className={`${type}`} />;
};

export default ImageOverlayContainer;
