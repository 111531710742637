import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { LoginSocial } from 'views/PreRegister/components';

const BattleRoyaleSticker = () => {
  const [isVisible, setVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className='fixed sm:right-[40px] bottom-1/4 right-[24px] z-50'>
      <div
        className='relative flex items-end justify-center w-[140px] h-[180px] lg:w-[253px] lg:h-[320px]'
        style={{
          background: `url(${require('assets/components/register_sticker.png').default})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='absolute bottom-[-100px] left-0 right-0 flex justify-center'>
          <Button
            size='small'
            color='inherit'
            className='w-[36px] h-[36px] rounded-full'
            onClick={() => setVisible(false)}
          >
            <Close />
          </Button>
        </div>
        <Button
          href='https://battleroyale.mymetafarm.com'
          target='_blank'
          rel='noreferrer'
          className='text-lg lg:text-2xl text-[#722828] px-4 font-black cursor-pointer bottom-[-40px] lg:py-3 lg:px-[28px] lg:bottom-[-56px]'
          style={{
            background: `url(${require('assets/components/Rectangle.png').default})`,
            backgroundSize: 'cover',
          }}
          disableElevation
        >
          EXPLORE
        </Button>

        <div hidden>
          <LoginSocial title='or Sign In with' />
        </div>
      </div>
    </div>
  );
};

export default BattleRoyaleSticker;
