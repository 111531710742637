import { ArrowBack } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import { CountdownTimer } from 'components';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { publicRoute } from 'routes';
import { marketService } from 'services';
import { CardAirdropItem } from 'views/Cards';
import { CloudAnimate } from './components';

const MetaverseEvent = () => {
  const params = useParams();

  const { data: event, isSuccess } = useQuery(['marketService.getAirdropEventById', { id: params.id }], () =>
    marketService.getAirdropEventById({ id: params.id as string }),
  ) as { data: AirdropEvent; isSuccess: boolean };

  if (!isSuccess) return <></>;
  return (
    <div style={{ background: 'linear-gradient(180deg, #C1F1FF 0%, #C1F1FF 80%, #0000 100%)' }}>
      <Container className='py-20 relative'>
        <div>
          <Link to={publicRoute.metaverse.path}>
            <Button variant='text' color='inherit' startIcon={<ArrowBack />}>
              Back
            </Button>
          </Link>
        </div>

        <Container maxWidth='sm' className='flex flex-col items-center gap-3 text-center mb-10'>
          <div className='font-black text-2xl text-info-dark'>{event.name}</div>
          <CountdownTimer endTime={event.toDate} />
          <div className='font-semibold text-sm whitespace-pre-line'>{event.description}</div>
        </Container>

        <Container maxWidth='lg' className='flex flex-col gap-10 px-0'>
          {event.events.map((item) => (
            <CardAirdropItem key={item.id} item={item} event={event} />
          ))}
        </Container>

        <CloudAnimate />
      </Container>
    </div>
  );
};

export default MetaverseEvent;
