import { FormControl, TextField } from '@mui/material';
import { DesignButton, InputPassword } from 'components';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { accountService } from 'services';
import { LoginSocial, TabConnectWallet } from '.';

const TabAccountLinking = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoggedIn, address } = useSelector(profileSelector);
  const { control, handleSubmit } = useForm();

  const { mutate, isLoading } = useMutation(accountService.linkAccount, {
    onSuccess: () => {
      enqueueSnackbar('Successfully linked to your account');
      accountService.getProfile({ address }).then((profile) => {
        dispatch(signIn(profile));
      });
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutate({
        ...values,
        linkType: 10,
      } as AccountLinkingType);
    })();
  };

  return !isLoggedIn ? (
    <TabConnectWallet />
  ) : (
    <div className='flex flex-col gap-[24px]'>
      <Controller
        name='email'
        defaultValue=''
        control={control}
        rules={{ required: 'Email is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Email</label>
            <TextField {...field} type='email' error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />

      <Controller
        name='password'
        defaultValue=''
        control={control}
        rules={{ required: 'Password is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth>
            <label>Password</label>
            <InputPassword {...field} error={invalid} helperText={error?.message} />
          </FormControl>
        )}
      />

      <div className='flex justify-center'>
        <DesignButton className='px-[48px]' loading={isLoading} onClick={handleClickSubmit}>
          LINK NOW
        </DesignButton>
      </div>

      <LoginSocial isLinkingAccount title='or Sign In with' onClose={onClose} />
    </div>
  );
};

export default TabAccountLinking;
