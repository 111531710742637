import { Avatar, CardMedia, Container, Tab, Tabs } from '@mui/material';
import { useTabs } from 'hooks';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { shorten } from 'utils/common';
import { TabInventory, TabListing } from './components';

const Inventory = () => {
  const { address } = useSelector(profileSelector);

  const tabs = [
    { code: 'owned', label: 'Owned', component: <TabInventory /> },
    { code: 'listing', label: 'Listing', component: <TabListing /> },
  ];

  const [activeTab, onTabChange] = useTabs(tabs);

  return (
    <Container className='pt-3'>
      <CardMedia
        image={require('assets/images/profile-cover.png').default}
        className='md:h-[320px] h-[180px] rounded-xl'
      />
      <div className='pb-20' style={{ marginTop: -60 }}>
        <div className='flex flex-col items-center gap-2 mb-10'>
          <Avatar
            src={require('assets/images/profile-avatar.png').default}
            className='md:w-[120px] md:h-[120px] w-[100px] h-[100px] bg-red-100 border-2'
          />
          <div className='font-semibold'>{shorten(address)}</div>
        </div>

        <Tabs
          value={activeTab}
          onChange={onTabChange}
          textColor='inherit'
          classes={{ flexContainer: 'justify-center border-b' }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs
          .filter((tab) => tab.code === activeTab)
          .map((tab) => (
            <div key={tab.code} className='py-10'>
              {tab.component}
            </div>
          ))}
      </div>
    </Container>
  );
};

export default Inventory;
