import { Dialog } from '@mui/material';
import { parse } from 'qs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { RegisterPopup } from 'views/PreRegister';
import ReactGA from 'react-ga4';
import { DesignButton } from 'components';

const RegisterButton = () => {
  const location = useLocation();
  const { refCode } = parse(location.search, { ignoreQueryPrefix: true });
  const { emailVerified } = useSelector(profileSelector);

  const [isOpenRegister, setOpenRegister] = useState(false);

  useEffect(() => {
    if (refCode) {
      setOpenRegister(true);
    }
  }, [refCode]);

  if (emailVerified) return null;

  return (
    <>
      <DesignButton
        color='info'
        onClick={() => {
          ReactGA.event({
            category: 'PRE-REGISTER',
            action: 'Pre Register',
            value: 1,
          });
          setOpenRegister((prev) => !prev);
        }}
        className='hidden sm:block'
      >
        REGISTER
      </DesignButton>
      <Dialog fullWidth maxWidth='xs' open={isOpenRegister} classes={{ container: 'items-start', paper: 'top-[60px]' }}>
        <RegisterPopup onClose={() => setOpenRegister(false)} />
      </Dialog>
    </>
  );
};

export default RegisterButton;
