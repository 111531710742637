import { FilterAltOutlined } from '@mui/icons-material';
import { Button, Container, Grid, Pagination } from '@mui/material';
import { useSearch } from 'hooks';
import { parse, stringify } from 'qs';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { publicRoute } from 'routes';
import { marketService } from 'services';
import { CardSaleItem } from 'views/Cards';
import { MarketFilter } from './components';

const Marketplace = () => {
  const location = useLocation();
  const { page } = parse(location.search, { ignoreQueryPrefix: true });

  const [dataSearch, onSearchChange] = useSearch({ page });
  const [showMenu, setShowMenu] = useState(false);

  const { data } = useQuery(['marketService.fetchSales', dataSearch], () => marketService.fetchSales(dataSearch), {
    keepPreviousData: true,
    staleTime: 0,
    onSuccess: () => {
      window.history.replaceState(null, '', stringify(dataSearch, { addQueryPrefix: true }));
    },
  });
  const { items = [], total = 0, currentPage, pages: totalPage } = data ?? {};

  return (
    <Container className='py-10'>
      <div className='flex gap-5 mb-6'>
        <div className='font-bold text-2xl'>Marketplace</div>

        <Button
          variant='text'
          color='inherit'
          classes={{ textInherit: 'bg-white hover:brightness-90 px-4 md:hidden ml-auto' }}
          endIcon={<FilterAltOutlined />}
          onClick={() => setShowMenu((prev) => !prev)}
        >
          Filter
        </Button>
        <div className='mobile-filter' style={{ display: showMenu ? 'flex' : 'none' }}>
          <MarketFilter onSearchChange={onSearchChange} />
        </div>
      </div>

      <div className='mb-6'>
        <div>{total} items found</div>
      </div>

      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item xl={12 / 5} lg={3} md={4} sm={6} xs={12} key={item.id}>
            <Link to={publicRoute.itemView.url(item)!}>
              <CardSaleItem item={item} />
            </Link>
          </Grid>
        ))}
      </Grid>
      <div className='flex justify-center my-8'>
        <Pagination
          page={currentPage ?? 1}
          count={totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </Container>
  );
};

export default Marketplace;
