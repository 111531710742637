import { CardMedia, Dialog, Grid, Paper } from '@mui/material';
import { DesignButton } from 'components';
import { ethersContract, web3Contract } from 'contracts';
import { useValidNetwork } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { glipService, walletService } from 'services';
import { PopupAirdrop } from '.';

const CardAirdropItem = ({ item, event }: { item: AirdropItem; event: AirdropEvent }) => {
  const { isGlip, isLoggedIn, address } = useSelector(profileSelector);
  const { metaverseContractAddress } = useSelector(systemSelector);
  const { validNetwork } = useValidNetwork();

  const [isOpenClaim, setOpenClaim] = useState(false);

  const { data: isInWhitelist } = useQuery(
    ['whitelistContract.isInWhitelist', { contract: item.whitelistContract, address }],
    async () => {
      if (isGlip) {
        const glipWallet = await glipService.getGlipWallet();
        const signer = await glipWallet.getSigner();
        return ethersContract(item.whitelistContract, 'WHITELIST', signer).isInWhitelist(address);
      } else {
        return web3Contract(item.whitelistContract, 'WHITELIST').methods.isInWhitelist(address).call();
      }
    },
    { enabled: isLoggedIn },
  );

  const { data: alreadyClaimed } = useQuery(
    ['metaverseContract.metaverseEventClaims', { onchainId: item.onchainId, address }],
    async () => {
      if (isGlip) {
        const glipWallet = await glipService.getGlipWallet();
        const signer = await glipWallet.getSigner();
        return ethersContract(metaverseContractAddress, 'METAVERSE', signer).metaverseEventClaims(
          item.onchainId,
          address,
        );
      } else {
        return web3Contract(metaverseContractAddress, 'METAVERSE')
          .methods.metaverseEventClaims(item.onchainId, address)
          .call();
      }
    },
    { enabled: isLoggedIn },
  );

  console.log({ isInWhitelist, alreadyClaimed });

  return (
    <Paper className='md:p-10 p-4 rounded-[24px] shadow-md' data-aos='fade-up'>
      <Grid container spacing={5}>
        <Grid item md={5.5} xs={12}>
          {item.itemVideo ? (
            <CardMedia
              component='video'
              image={item.itemVideo}
              className='max-w-[380px] rounded-[20px] mx-auto'
              playsInline
              autoPlay
              loop
              muted
              controls
              controlsList='nodownload'
            />
          ) : (
            <CardMedia image={item.itemImage} className='h-full min-h-[240px] bg-contain' />
          )}
        </Grid>
        <Grid item md={6.5} xs={12} className='flex flex-col items-start gap-3'>
          <div className='bg-info-light text-white font-black rounded-[8px] px-3 py-1'>{event.name}</div>
          <div className='font-black text-2xl text-info-dark'>{item.name}</div>

          <div className='font-semibold text-sm whitespace-pre-line'>{item.description}</div>
          <div className='font-bold text-orange-700'>{item.condition}</div>
          <div className='flex-1 flex md:flex-row flex-col items-end md:gap-6 gap-3'>
            {isLoggedIn ? (
              <DesignButton
                className='w-40'
                variant='contained'
                disabled={!isInWhitelist || alreadyClaimed}
                onClick={() => validNetwork(() => setOpenClaim(true))}
              >
                {alreadyClaimed ? 'CLAIMED' : 'CLAIM'}
              </DesignButton>
            ) : (
              <DesignButton className='w-40' onClick={() => validNetwork(walletService.connectWallet)}>
                Connect Wallet
              </DesignButton>
            )}
          </div>
          <Dialog open={isOpenClaim} fullWidth maxWidth='sm'>
            <PopupAirdrop item={item} onClose={() => setOpenClaim(false)} />
          </Dialog>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardAirdropItem;
