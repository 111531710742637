import { DialogContent, DialogTitle, FormControl, TextField, DialogActions, Typography, Grid } from '@mui/material';
import { CloseButton } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { accountService } from 'services';
import { useSelector, useDispatch } from 'react-redux';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { DesignButton } from 'components';

const ReferralCodePopup = ({ onClose }: PopupController) => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const { address, parentUser, isLoggedIn } = useSelector(profileSelector);

  const { mutate, isLoading } = useMutation(accountService.updateProfile, {
    onSuccess: () => {
      onClose();
    },
    onSettled: () => {
      if (isLoggedIn) {
        accountService.getProfile({ address }).then((profile) => {
          dispatch(signIn(profile));
        });
      }
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      mutate(values);
    })();
  };

  return (
    <>
      <DialogTitle>Get Referral Code</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1' className='text-[#0A1316] font-semibold'>
              Use the referral code from another member here and you will both earn reward!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='parentUserCode'
              defaultValue={parentUser?.refCode}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth>
                  <label>Enter Your Referral Code</label>
                  <TextField {...field} type='text' error={invalid} helperText={error?.message} />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className='flex justify-center w-full'>
          <DesignButton onClick={handleClickSubmit} loading={isLoading}>
            NEXT
          </DesignButton>
        </div>
      </DialogActions>
      <CloseButton onClick={onClose} />
    </>
  );
};

export default ReferralCodePopup;
